import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Content from "../components/Content";
import CustomInput from "../components/CustomInput";
import { RxCross2 } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { delImg, uploadImg } from "../features/upload/uploadSlice";
import axios from "axios";
import {
  createManufacturer,
  getManufacturer,
  updateManufacturer,
} from "../features/Manufacturer/ManufacturerService";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { base_url } from "../utils/baseUrl";
import { config } from "../utils/axiosconfig";

let schema = yup.object().shape({
  manufacturerName: yup.string().required("Manufacturer Name is Required"),
  description: yup.string().required("Description is Required"),
  images: yup.mixed().required("Image is Required"),
});

const Manufacturer = () => {
  const [previewImage, setPreviewImage] = useState(null);
  const [img, setImg] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [info, setInfo] = useState({});
  const [check, setCheck] = useState(false);
  const [imgID, setImgID] = useState([]);
  const location = useLocation();
  const mID = location.pathname.split("/")[3];

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const response = await getManufacturer(mID);
        setInfo(response);
        console.log(
          response.images[0].fileName,
          "delete images in manufacturer---"
        );
        setImgID([response.images[0].fileName]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchInfo();
  }, [mID]);

  useEffect(() => {
    if (info.images && info.images.length > 0) {
      setPreviewImage(info.images[0].fileUrl);
    }
  }, [info]);

  useEffect(() => {
    if (mID === undefined) {
      setInfo("");
      setPreviewImage("");
      setImg("");
    }
  }, [mID]);

  const formik = useFormik({
    initialValues: {
      manufacturerName: info.manufacturerName || "",
      description: info.description || "",
      images: info.images || "",
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        if (mID != undefined) {
          if (check) {
            console.log(imgID, "deleting---");
            handleDeleteImage(imgID);
            const imageUrl = await handleImageUpload();
            const formData = {
              ...values,
              images: imageUrl?.payload?.data,
            };
            const response = await updateManufacturer(mID, formData);
            if (response) {
              toast.success("Manufacturer Updated Successfullly!");
              setCheck(false);
              navigate("/admin/manufacturerList");
            }
          } else {
            const response = await updateManufacturer(mID, values);
            if (response) {
              toast.success("Manufactuere Updated Successfully!");
              setCheck(false);
              navigate("/admin/manufacturerList");
            }
          }
        } else {
          if (values.images) {
            const imageUrl = await handleImageUpload();
            console.log(imageUrl, "inside image");
            const formData = {
              ...values,
              images: imageUrl?.payload?.data,
            };

            const response = await createManufacturer(formData);
            if (response) {
              toast.success("Manufacturer Added Successfullly!");
              navigate("/admin/manufacturerList");
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleDeleteImage = async (imgID) => {
    let payload = { data: imgID };
    try {
      const response = await axios.post(
        `${base_url}uploadTo/deleteMultipleDocData`,
        payload,
        config
      );
      formik.setFieldValue("images", []);
      setPreviewImage(null);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };


  
  const handleRemoveImage = () => {
    formik.setFieldValue("images", null);
    setPreviewImage(null);
    if (mID != undefined) {
      setCheck(true);
    } else {
      setCheck(false);
    }
  };

  const handleImageUpload = async () => {
    try {
      const imageUrl = await dispatch(uploadImg(img));
      return imageUrl;
    } catch (error) {
      console.error("Image upload failed:", error);
      throw error;
    }
  };

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    setImg([file]);
    formik.setFieldValue("images", file);
    setPreviewImage(URL.createObjectURL(file));
  };

  return (
    <Content>
      <div>
        <h4 className="mb-4 title">
          {mID !== undefined ? "Edit" : "Add"} Manufacturer
        </h4>
        <form onSubmit={formik.handleSubmit}>
          <CustomInput
            type="text"
            label="Enter Manufacturer Name"
            id="manufacturerName"
            name="manufacturerName"
            onChng={formik.handleChange}
            onBlr={formik.handleBlur}
            val={formik.values.manufacturerName}
          />
          {formik.touched.manufacturerName && formik.errors.manufacturerName ? (
            <div className="error">{formik.errors.manufacturerName}</div>
          ) : null}

          <div className="form-floating mt-3">
            <textarea
              style={{ height: "13vh" }}
              className="form-control"
              id="description"
              placeholder="Description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              rows={4}
              cols={50}
            />
            <label htmlFor="description">Enter Description</label>
          </div>

          <div className="mt-3">
            <label htmlFor="images" className="form-label">
              {mID != undefined ? "Edit" : "Add"} Manufacturer Icon
            </label>
            <div className="p-4 d-flex align-items-center justify-content-center bg-light">
              {previewImage ? (
                <div className=" position-relative">
                  <div>
                    <img
                      src={previewImage}
                      alt="Preview"
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                    />
                    <span
                      className="position-absolute text-danger top-0 end-0 rounded-circle "
                      style={{ cursor: "pointer" }}
                      onClick={handleRemoveImage}
                    >
                      <RxCross2 size={22} />
                    </span>
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-center flex-column">
                  <p className="text-muted mb-2">Drop files here to upload</p>
                  <label htmlFor="images" className="btn btn-primary">
                    Upload file
                    <input
                      type="file"
                      id="images"
                      name="images"
                      className="d-none"
                      onChange={handleFileChange}
                      accept="image/*"
                    />
                  </label>
                </div>
              )}
            </div>
          </div>
          {formik.touched.images && formik.errors.images ? (
            <div className="error">{formik.errors.images}</div>
          ) : null}

          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary border-0 rounded-3 my-5"
              type="submit"
            >
              {mID != undefined ? "Update" : "Add"} Manufacturer
            </button>
          </div>
        </form>
      </div>
    </Content>
  );
};

export default Manufacturer;
