import React, { useEffect, useState } from 'react';
import { Modal, Select, Button, DatePicker } from 'antd';
import { City, Country, State } from 'country-state-city';
import moment from 'moment';
import { DeleteDocuments, UpdateCustomer, uploadDocuments } from '../features/customers/CustomerService';
import Loader from '../components/Loader/Loader';

const { Option } = Select;

const CustomerEdit = ({ open, onClose, info, fetchData }) => {
    const countries = Country.getAllCountries();
    const [name, setName] = useState('');
    const [fileUpload, setFileUpload] = useState(null);
    const [dob, setDob] = useState(null);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const userToken = JSON.parse(localStorage.getItem("user"));
console.log(info.fileName)
    useEffect(() => {
        if (info) {
            setName(info.firstname || '');
            // setFileUpload(info.firstname || '');
            setDob(info.date_of_birth ? moment(info.date_of_birth) : null);
            setSelectedCountry(info.country || '');
            setSelectedState(info.state || '');
            setSelectedCity(info.city || '');

            if (info.country) {
                fetchStates(info.country);
                if (info.state) {
                    fetchCities(info.state, info.country);
                }
            }
        }
    }, [info]);

    const fetchStates = (countryName) => {
        const country = countries.find(item => item.name === countryName);
        if (country) {
            const stateList = State.getStatesOfCountry(country.isoCode);
            setStates(stateList);
            setCities([]); // Reset cities
        }
    };

    const fetchCities = (stateName, countryName) => {
        const state = states.find(item => item.name === stateName);
        if (state) {
            const country = countries.find(item => item.name === countryName);
            const cityList = City.getCitiesOfState(country.isoCode, state.isoCode);
            setCities(cityList);
        }
    };

    const handleCountryChange = (value) => {
        setSelectedCountry(value);
        fetchStates(value); // Fetch states based on selected country
    };

    const handleStateChange = (value) => {
        setSelectedState(value);
        setSelectedCity(''); // Reset city when state changes
        fetchCities(value, selectedCountry); // Fetch cities based on selected state and country
    };

    const handleFileInput = (event) => {
        setFileUpload(event.target.files[0]); // Set the selected file
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        let uploadResponse = {
            fileUrl: "",
            fileName: ""
        };
    
        // Prepare payload for deleting existing documents
        const deletePayload = {
            data: [{ userId: info?._id, profile: info.profile?.fileName }] // Assuming profile.fileName has the name of the existing file
        };
    
        try {
            // Delete existing documents
            await DeleteDocuments(deletePayload);
            console.log('Existing document deleted.');
    
            // If there's a new file to upload
            if (fileUpload) {
                let formData = new FormData();
                formData.append("upload", fileUpload);
    
                // Upload the new document
                const response = await uploadDocuments(formData);
                uploadResponse = response.data[0]; // Assuming response structure
                console.log('New document uploaded:', uploadResponse);
            }
        } catch (error) {
            console.error('Error during document operations:', error);
        }
    
        // Prepare updated data for customer
        const updatedData = {
            firstname: name,
            profile: uploadResponse,
            date_of_birth: dob ? dob.format('YYYY-MM-DD') : null,
            country: selectedCountry,
            state: selectedState,
            city: selectedCity,
            userId: info._id
        };
    
        const config = {
            headers: {
                Authorization: `Bearer ${userToken?.token}`
            }
        };
    
        try {
            const res = await UpdateCustomer(updatedData, config);
            console.log('Updated Data:', res);
            fetchData(); // Fetch updated data
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
            onClose();
            fetchData();
        }
    };

    return (
        <Modal
            okText="Update"
            centered
            title="Edit Customer"
            open={open}
            onCancel={onClose}
            width={800}
            onOk={handleSubmit}
        >
            <form onSubmit={handleSubmit}>
                {isLoading && <Loader open={isLoading} />}
                <div className="row">
                    <div className="col-md-6 form-group mb-2">
                        <label htmlFor="name" className='labelsHeading'>Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Enter Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                      
                    </div>

                    <div className="col-md-6 form-group mb-2">
                        <label className='labelsHeading'>State</label>
                        <Select
                            placeholder="Select a state"
                            onChange={handleStateChange}
                            value={selectedState}
                            style={{ width: '100%' }}
                            disabled={!selectedCountry}
                        >
                            {states.map(state => (
                                <Option key={state.isoCode} value={state.name}>{state.name}</Option>
                            ))}
                        </Select>
                    </div>

                    <div className="col-md-6 form-group mb-2">
                        <label className='labelsHeading'>City</label>
                        <Select
                            placeholder="Select city"
                            value={selectedCity}
                            style={{ width: '100%' }}
                            onChange={setSelectedCity}
                            disabled={!selectedState}
                        >
                            {cities.map(city => (
                                <Option key={city.id} value={city.name}>{city.name}</Option>
                            ))}
                        </Select>
                    </div>

                    <div className="col-md-6 form-group mb-2">
                        <label className='labelsHeading'>Date of Birth</label>
                        <DatePicker
                            style={{ width: '100%' }}
                            value={dob}
                            onChange={(date) => setDob(date)}
                            placeholder="Select Date of Birth"
                        />
                    </div>
                    <div className="col-md-6 form-group">
                        <label className='labelsHeading'>Profile Img</label>
                        <input
                            type="file"
                            className="form-control"
                            onChange={handleFileInput}
                            required
                            // value={info.fileName}
                        />
                          <span>{info.fileName}</span>
                    </div>

                </div>
            </form>
        </Modal>
    );
};

export default CustomerEdit;
