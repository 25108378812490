import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import Content from "../components/Content";
import CustomModal from "../components/CustomModal";
import { deleteSupplier, getSuppliers } from "../features/supplier/supplierService";
import { toast } from "react-toastify";


const SupplierList = () => {
  const [sData, setSData] = useState([])
  const [sID, setSID] = useState()
  const [open, setOpen] = useState(false);


  const showModal = (id) => {
    setOpen(true);
    setSID(id);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const columns = [
    {
      title: "SNo",
      dataIndex: "key",
    },
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Vendor Code",
      dataIndex: "vendorCode",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  const fetchData = async () => {
    try {
      const response = await getSuppliers()
      setSData(response)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])



  const data1 = sData?.map((item, index) => ({
    key: index + 1,
    supplierName: item.supplierName,
    vendorCode: item.vendorCode,
    action: (
      <>
        <Link to={`/admin/supplier/${item._id}`} className="fs-3 text-success">
          <BiEdit />
        </Link>
        <button
          className="ms-3 fs-3 text-danger bg-transparent border-0"
          onClick={() => showModal(item._id)}
        >
          <AiFillDelete />
        </button>
      </>
    ),
  }));

  const handleSupplier = async (sID) => {
    try {
      const response = await deleteSupplier(sID);
      if (response) {
        toast.success("Supplier Deleted Successfully!");
        await fetchData()
        setOpen(false)
      }
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <Content>
      <h4 className="mb-4 title">Supplier List</h4>
      <div>
        <Table columns={columns} dataSource={data1} />
      </div>
      <CustomModal
        hideModal={hideModal}
        open={open}
        performAction={() => handleSupplier(sID)}
        title="Are you sure you want to delete this Supplier?"
      />
    </Content>
  );
};

export default SupplierList;
