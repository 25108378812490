import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";

export const getOrder = async () => {
  const info = JSON.parse(localStorage.getItem("user"));
  const userToken = info.token;

  try {
    const response = await axios.get(`${base_url}order/getallorders`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching orders:", error);
  }
};
