// InvoiceModal.js
import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const RatingModel = ({ open, toggle, data, submit, handleRatingChange }) => {
console.log(data)
 
  return (
    <>
      <Modal isOpen={open} centered >
        <ModalHeader toggle={toggle}>
          Update Ratings
        </ModalHeader>
        <ModalBody>
          <form onSubmit={submit}>
            <label className="mb-1">Ratings (Below 5)</label>
            <input type='number' className="form-control" value={data?.avg} onChange={(e) => {
              if (+(e.target.value) <= 5) {
                handleRatingChange(e)
              }
            }} name='avg' placeholder="Enter ratings here (Below 5)" />
            <label className="mb-1 mt-3">Count</label>
            <input type='number' className="form-control" name='count' onChange={handleRatingChange} value={data?.count} placeholder="Enter count here" />
            <div class="form-check form-switch mt-3">
              <input class="form-check-input" type="checkbox" role="switch" name='use_admin_rating' onChange={handleRatingChange} id="flexSwitchCheckChecked" checked={data?.use_admin_rating} />
              <label class="form-check-label" for="flexSwitchCheckChecked">User Admin Rating</label>
            </div>
            <div className="d-flex justify-content-end gap-3 mt-3">
              <button className='btn btn-sm border' type="button" onClick={toggle}>Cancel</button>
              <button className="btn btn-sm btn-primary" type="submit">Submit</button>
            </div>
          </form>
        </ModalBody>

      </Modal>
    </>
  );
};

export default RatingModel;
