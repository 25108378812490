import React from "react";
import Content from "../../components/Content";

const Userreports = () => {
  return (
    <Content>
      <div>
        <h4 className="mb-4 title">User Management Reports</h4>
      </div>
    </Content>
  );
};

export default Userreports;
