import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import Content from "../components/Content";
import {
  deleteManufacturer,
  getManufacturers,
} from "../features/Manufacturer/ManufacturerService";
import CustomModal from "../components/CustomModal";
import { toast } from "react-toastify";

const ManufacturerList = () => {
  const [mData, setMData] = useState([]);
  const [open, setOpen] = useState(false);
  const [mID, setMID] = useState();

  const showModal = (id) => {
    setOpen(true);
    setMID(id);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const columns = [
    {
      title: "SNo",
      dataIndex: "key",
    },
    {
      title: "Manufacturer Name",
      dataIndex: "manufacturerName",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  const fetchData = async () => {
    try {
      const response = await getManufacturers();
      setMData(response);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const data = mData.map((item, index) => ({
    key: index + 1,
    manufacturerName: item?.manufacturerName,
    action: (
      <>
        <Link
          to={`/admin/manufacturer/${item?._id}`}
          className="fs-3 text-success"
        >
          <BiEdit />
        </Link>
        <button
          className="ms-3 fs-3 text-danger bg-transparent border-0"
          onClick={() => showModal(item?._id)}
        >
          <AiFillDelete />
        </button>
      </>
    ),
  }));

  const handleManufacturer = async (mID) => {
    try {
      const response = await deleteManufacturer(mID);
      if (response) {
        toast.success("Manufacturer Deleted Successfully!");
        await fetchData();
        setOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Content>
      <h4 className="mb-4 title">Manufacturer List</h4>
      <div>
        <Table columns={columns} dataSource={data} />
      </div>
      <CustomModal
        hideModal={hideModal}
        open={open}
        performAction={() => handleManufacturer(mID)}
        title="Are you sure you want to delete this Manufacturer?"
      />
    </Content>
  );
};

export default ManufacturerList;
