import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  createCoupon,
  getACoupon,
  resetState,
  updateACoupon,
} from "../features/coupon/couponSlice";
import Content from "../components/Content";
import CustomInput from "../components/CustomInput";

const schema = yup.object().shape({
  name: yup.string().required("Coupon Name is Required"),
  expiry: yup.date().required("Expiry Date is Required"),
  startDate: yup.date().required("Start Date is Required"),
  startTime: yup.string().required("Start Time is Required"),
  discount: yup.number().required("Discount Percentage is Required"),
});

const AddCoupon = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const getCouponId = location.pathname.split("/")[3];
  const {
    isSuccess,
    isError,
    createdCoupon,
    couponName,
    couponDiscount,
    couponExpiry,
    updatedCoupon,
  } = useSelector((state) => state.coupon);

  useEffect(() => {
    if (getCouponId !== undefined) {
      dispatch(getACoupon(getCouponId));
    } else {
      dispatch(resetState());
    }
  }, [getCouponId, dispatch]);

  useEffect(() => {
    if (isSuccess && createdCoupon) {
      navigate("/admin/coupon-list");
      toast.success("Coupon Added Successfully!");
      formik.resetForm();
      dispatch(resetState());
    }
    if (isSuccess && updatedCoupon) {
      toast.success("Coupon Updated Successfully!");
      navigate("/admin/coupon-list");
    }
    if (isError && couponName && couponDiscount && couponExpiry) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, isError, createdCoupon, updatedCoupon]);

  const changeDateFormat = (date) => {
    if (!date) return "";
    const newDate = new Date(date).toLocaleDateString();
    const [month, day, year] = newDate.split("/");
    return [year, month, day].join("-");
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: couponName || "",
      expiry: changeDateFormat(couponExpiry) || "",
      startDate: changeDateFormat(couponExpiry) || "",
      startTime: "",
      discount: couponDiscount || "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (getCouponId !== undefined) {
        dispatch(updateACoupon({ id: getCouponId, couponData: values }));
      } else {
        dispatch(createCoupon(values));
      }
    },
  });

  return (
    <Content>
      <div>
        <h4 className="mb-4 title">
          {getCouponId !== undefined ? "Edit" : "Add"} Coupon
        </h4>
        <form onSubmit={formik.handleSubmit}>
          <CustomInput
            type="text"
            name="name"
            onChng={formik.handleChange}
            onBlr={formik.handleBlur}
            val={formik.values.name}
            label="Enter Coupon Name"
            id="name"
          />
          <div className="error">
            {formik.touched.name && formik.errors.name}
          </div>
          <CustomInput
            type="date"
            name="expiry"
            onChng={formik.handleChange}
            onBlr={formik.handleBlur}
            val={formik.values.expiry}
            label="Enter Expiry Date"
            id="expiry"
          />
          <div className="error">
            {formik.touched.expiry && formik.errors.expiry}
          </div>
          <CustomInput
            type="date"
            name="startDate"
            onChng={formik.handleChange}
            onBlr={formik.handleBlur}
            val={formik.values.startDate}
            label="Enter Start Date"
            id="startDate"
          />
          <div className="error">
            {formik.touched.startDate && formik.errors.startDate}
          </div>
          <CustomInput
            type="time"
            name="startTime"
            onChng={formik.handleChange}
            onBlr={formik.handleBlur}
            val={formik.values.startTime}
            label="Enter Start Time"
            id="startTime"
          />
          <div className="error">
            {formik.touched.startTime && formik.errors.startTime}
          </div>
          <CustomInput
            type="number"
            name="discount"
            onChng={formik.handleChange}
            onBlr={formik.handleBlur}
            val={formik.values.discount}
            label="Enter Discount"
            id="discount"
          />
          <div className="error">
            {formik.touched.discount && formik.errors.discount}
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary border-0 rounded-3 my-5"
              type="submit"
            >
              {getCouponId !== undefined ? "Edit" : "Add"} Coupon
            </button>
          </div>
        </form>
      </div>
    </Content>
  );
};

export default AddCoupon;
