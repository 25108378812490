import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

export const getAppointments = async () => {
  const response = await axios.get(`${base_url}appointment/allAppointments`);
  return response.data;
};


export const updateAppointmentById = async (paylaod, config) => {
  const response = await axios.put(`${base_url}appointment/rescheduleAppointment`, paylaod, config);
  return response.data;
}


export const slotByProfessionalId = async (paylaod) => {
  const response = await axios.post(`${base_url}availability/slots`, paylaod);
  return response.data;
}