import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Content from "../components/Content";
import { getOrder } from "../features/orders/OrderService";
import moment from "moment";
import { render } from "@testing-library/react";

const Orders = () => {
  const [orders, setOrders] = useState([]);

  const fetchData = async () => {
    const response = await getOrder();
    console.log(response?.orders);
    setOrders(response?.orders);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const data = orders?.map((item, index) => {
    const date = moment(item?.createdAt).utc().format("DD/MM/YYYY");
    return {
      key: index + 1,
      name: item?.user?.firstname,
      amount: item?.totalPriceAfterDiscount,
      date: date,
      status: item?.orderStatus,
      paymentMode: item?.paymentMode,
    };
  });

  const columns = [
    {
      title: "SNo",
      dataIndex: "key",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    // {
    //   title: "Product",
    //   dataIndex: "product",
    // },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Payment Mode",

      dataIndex: "paymentMode",
    },

    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
  ];
  return (
    <Content>
      <div>
        <h4 className="mb-4 title">Orders</h4>
        <div>{<Table columns={columns} dataSource={data} />}</div>
      </div>
    </Content>
  );
};

export default Orders;
