// InvoiceModal.js
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Content from "../components/Content";
import { statusChange, updateAdminRatings, viewProfessional } from "../services/apis";
import { useMediaQuery } from "@mui/material";
import { IoArrowBack } from "react-icons/io5";
import style from './style.module.css'
import { FaCamera } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { FaRegStarHalfStroke } from "react-icons/fa6";
import { FaStar } from "react-icons/fa6";
import { FaRegStar } from "react-icons/fa";
import { Modal, ModalBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Map } from "../components/LocationViewModal/LocationViewModal";
import DocumentCard from "../components/DocumentCard/DocumentCard";
import { FaEdit } from "react-icons/fa";
import Loader from "../components/Loader/Loader";
import { toast } from "react-toastify";
import RatingModel from "./RatingModel";



const ProfessionalView = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const matches = useMediaQuery('(min-width:768px)');
  const [activeTab, toggleTab] = useState('0')
  const [showData, setShowData] = useState({})

  const [statusValue, setStatusValue] = useState('')
  const [reasonForSuspend, setReasonForSuspend] = useState('')
  const [statusModal, setStatusModal] = useState(false)

  const [ratingModal, setRatingModal] = useState(false)
  const [ratingData, setRatingData] = useState({
    count: '',
    avg: '',
    use_admin_rating: ''
  })


  async function getDataById() {
    setLoader(true)
    try {
      let res = await viewProfessional(id)
      let data = res?.data?.user || {}
      setShowData(data)
      setStatusValue(data?.profile_status)
      setRatingData({
        count: data?.admin_rating?.count,
        avg: data?.admin_rating?.avg,
        use_admin_rating: data?.use_admin_rating
      })
      console.log(data)

    } catch (err) {
      console.log(err)
    } finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    getDataById()
  }, [])


  function statuscolor(key) {
    switch (key) {
      case 'approved':
        return 'text-success'
        break;
      case 'under review':
        return 'text-warning'
        break;
      case 'disapproved':
        return 'text-danger'
        break;

      default:
        break;
    }
  }

  async function changeStatus2(e) {
    e.preventDefault()
    if (statusValue == 'under review') {
      toast.error("You can't update status as Under Review")
      return
    }
    setLoader(true)
    let payload = {
      "status": statusValue,
      "reason": reasonForSuspend
    }
    try {
      await statusChange(showData?._id, payload)
      await getDataById()
      toggleModal()
      toast.success('Status update successfully')
    } catch (err) {
      console.log(err)
      toast.error('There are some error occupide please login again then continue')
    } finally {
      setLoader(false)
    }
  }

  function toggleModal() {
    setStatusModal(false)
    setReasonForSuspend('')
    setStatusValue(showData?.profile_status)
  }

  function changeStatusUi(status) {
    switch (status) {
      case 'disapproved':
        return <div className="statusOuterin">
          <div className="statusDotin" />
          <div className="Statusin">Disapproved</div>
        </div>
        break;
      case 'under review':
        return <div className="statusOuterr">
          <div className="statusDotr" />
          <div className="Statusr">Under Review</div>
        </div>
        break;
      case 'approved':
        return <div className="statusOuterA">
          <div className="statusDotA" />
          <div className="StatusA">Approved</div>
        </div>
        break;

      default:
        break;
    }
  }

  function toggle() {
    setRatingModal(false)
    setRatingData({
      count: showData?.admin_rating?.count,
      avg: showData?.admin_rating?.avg,
      use_admin_rating: showData?.use_admin_rating
    })
  }

  function handleRatingChange(e) {
    let { name, value, checked } = e.target
    if (name == 'use_admin_rating') {
      setRatingData(pre => {
        return {
          ...pre,
          [name]: checked
        }
      })
    } else {
      setRatingData(pre => {
        return {
          ...pre,
          [name]: value
        }
      })
    }
  }

  async function submitRatingData(e) {
    e.preventDefault()
    setLoader(true)
    try {
      let payload = {
        ...ratingData,
        id: showData?._id
      }
      await updateAdminRatings(payload)
      await getDataById()
      toggle()
      toast.success('Rating update successfully')
    } catch (err) {
      console.log(err)
       if (
         err?.response?.data?.message ==
         "Not Authorized token expired,Please Login again"
       ) {
         localStorage.clear();
         toast.error("session expired please login again.");
         navigate("/");
       } else {
         toast.error(
           "There are some error occupide please login again then continue"
         );
       }
    } finally{
      setLoader(false)
    }
  }


  return (
    <Content>
      <Loader open={loader} />
      <RatingModel
        open={ratingModal}
        toggle={toggle}
        submit={submitRatingData}
        data={ratingData}
        handleRatingChange={handleRatingChange}
      />
      <Modal isOpen={statusModal} centered >
        <ModalBody>
          <form className="mt-3" onSubmit={changeStatus2}>
            <label className="form-label fw-bold">Status</label>
            <select
              className={`form-select bg-transparent f-14 px-1 ${statuscolor(statusValue)}`}
              value={statusValue}
              onChange={(e) => setStatusValue(e.target.value)}
              style={{ minWidth: '130px' }}
            >
              <option className='f-14 text-success' value='approved'>Approved</option>
              <option className='f-14 text-warning' disabled value='under review'>Under Review</option>
              <option className='f-14 text-danger' value='disapproved'>Disapproved</option>
            </select>
            {statusValue == 'disapproved' ? <>
              <label className="form-label fw-bold mt-3">Enter Reason</label>
              <textarea className="form-control" required rows={5} placeholder="Enter your reason here" value={reasonForSuspend} onChange={(e) => {
                if (e.target.value == ' ') {
                  e.target.value = ''
                } else {
                  setReasonForSuspend(e.target.value)
                }
              }}></textarea>
            </> : ''}
            <div className="d-flex justify-content-end gap-3 mt-3">
              <button className='btn btn-sm border' type="button" onClick={toggleModal}>Cancel</button>
              <button className="btn btn-sm btn-primary" type="submit">Submit</button>
            </div>
          </form>
        </ModalBody>
      </Modal>
      <div className='d-flex align-items-center justify-content-between'>
        <button className='btn ps-0 d-flex align-items-center' onClick={() => navigate('/admin/professionals')}><IoArrowBack className='me-1' /> Back</button>
        {changeStatusUi(showData?.profile_status)}
      </div>
      <div className='mt-4'>
        <div className='d-flex align-items-center'>
          <div className='p-relative'>
            <div className={`${style.profileContainer}`}>
              <img src={showData?.profile?.fileUrl == '' ? showData?.gender == 'male' ? '/images/maleProfile.jpg' : '/images/femaleProfile.jpg' : showData?.profile?.fileUrl} className='w-100 h-100' />
            </div>
            {/* <label className={style.camera} htmlFor='profileChange'>
                <FaCamera className='text-white f-13' />
              </label> */}
            {/* <input type='file' hidden id='profileChange' onChange={changeProfileImg} /> */}
          </div>
          <div className='d-flex flex-column ms-md-4 ms-sm-3 ms-3'>
            <div className='fs-md-3 fs-sm-4 fs-5 fw-bold pt-3 text-secondary text-capitalize'>{showData?.prefix} {showData?.firstname}</div>
            <div className='text-gray'>{showData?.email}</div>
            <div className="d-flex align-items-center gap-3">
              <div>
                <RatingStars averageRating={showData?.use_admin_rating?showData?.admin_rating?.avg:showData?.averageRating} ratingCount={showData?.use_admin_rating?showData?.admin_rating?.count:showData?.totalRatings} />
              </div>
              <FaEdit className="fs-5 pointer" onClick={()=>setRatingModal(true)} />
            </div>
          </div>
        </div>
      </div>
      <div className={`mt-4 ${style.tabContainer}`}>
        <div className={`${style.left}`}>
          <Nav vertical={false} className={`${true ? 'border-bottom flex flex-nowrap' : ''}`}>
            <NavItem>
              <NavLink
                className={`text-secondary fw-bold ${activeTab == "0" ? true ? 'activeTab2' : 'activeTab text-white ' : ""} ${true ? 'px-md-4 px-sm-3 px-2' : 'rounded ps-4 mt-2 py-2'} pointer`}
                onClick={() => toggleTab("0")}>
                <div className='d-flex justify-content-between align-items-center'><div>Profile</div> <IoIosArrowForward className='fw-600 d-md-none d-sm-none d-none' /></div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`text-secondary fw-bold ${activeTab == "1" ? true ? 'activeTab2' : 'activeTab text-white ' : ""} ${true ? 'px-md-4 px-sm-3 px-2' : 'rounded ps-4 mt-2 py-2'} pointer`}
                onClick={() => toggleTab("1")}>
                <div className='d-flex justify-content-between align-items-center'><div>Degree/Diploma</div> <IoIosArrowForward className='fw-600 d-md-none d-sm-none d-none' /></div>
              </NavLink>
            </NavItem>
          </Nav>

        </div>
        <div className={`${style.right}`}>
          <TabContent className="px-md-3 px-sm-2 px-1 pt-md-4 pt-sm-4 pt-3" activeTab={activeTab}>
            <TabPane tabId="0">
              <div className='d-flex justify-content-between align-items-center'>
                <div className="fs-4 fw-600">Profile</div>
                {/* <button className="btn btn-sm btn-primary" onClick={() => setStatusModal(true)}>Update Status</button> */}
              </div>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label>Eye Care Organization</label>
                  <input className="form-control" disabled value={showData?.company} />
                </div>
                <div className="col-md-6 mt-3">
                  <label>Phone Number</label>
                  <input className="form-control" disabled value={showData?.mobile} />
                </div>
                <div className="col-md-6 mt-3">
                  <label>Specialities</label>
                  <input className="form-control" disabled value={showData?.speciality?.join(', ')} />
                </div>
                <div className="col-md-6 mt-3">
                  <label>Profession</label>
                  <input className="form-control" disabled value={showData?.profession} />
                </div>
                <div className="col-md-6 mt-3">
                  <label>Qualification</label>
                  <input className="form-control" disabled value={showData?.qualification} />
                </div>
                <div className="col-md-6 mt-3">
                  <label>Years of Experience</label>
                  <input className="form-control" disabled value={showData?.yearsOfExperience} />
                </div>
                <div className="col-md-6 mt-3">
                  <label>Per Day Eye Test in Current Practice</label>
                  <input className="form-control" disabled value={showData?.perDayEyeTest} />
                </div>
                <div className="col-md-6 mt-3">
                  <label>Slit Lamp Available</label>
                  <input className="form-control" disabled value={showData?.slitLampAvailable ? 'Yes' : 'No'} />
                </div>
                <div className="col-md-6 mt-3">
                  <label>Address</label>
                  {showData?.country?
                  <input className="form-control" disabled value={`${showData?.city}, ${showData?.state}, ${showData?.country}`} />
                : <input className="form-control" disabled  />}
                  
                </div>
                <div className="col-md-6 mt-3">
                  <label>Pin Code</label>
                  <input className="form-control" disabled value={showData?.pinCode ? showData?.pinCode : 'NA'} />
                </div>
                <div className="col-12 mt-3 mb-5">
                  <Map latitude={+(showData?.lat_long?.lat)} longitude={+(showData?.lat_long?.long)} />
                </div>
              </div>
            </TabPane>
            <TabPane tabId="1">
              <div>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='fs-5 fw-600 text-secondary'>Degree/Diploma</div>
                </div>
                <div className={`${style.docContainer} mt-4`}>
                  {showData?.degree_document?.length !== 0 ? showData?.degree_document?.map((res, index) => <DocumentCard
                    key={index}
                    file={res}
                  />):<div className="text-center fs-5 fw-bold">No document uploaded yet</div>}
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>


    </Content>
  );
};

export default ProfessionalView;

function RatingStars({ averageRating, ratingCount }) {
  const fullStars = Math.floor(averageRating);
  const hasHalfStar = averageRating % 1 !== 0;
  const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

  const renderStars = () => {
    const stars = [];


    for (let i = 0; i < fullStars; i++) {
      stars.push(<FaStar color="#FFD700" size={18} key={`full-${i}`} />);
    }


    if (hasHalfStar) {
      stars.push(<FaRegStarHalfStroke color="#FFD700" size={18} key="half" />);
    }


    for (let i = 0; i < emptyStars; i++) {
      stars.push(<FaRegStar  size={18} key={`empty-${i}`} />);
    }

    return stars;
  };

  return (
    <div>
      <div className="rating-stars">{renderStars()}</div>
      <div className="rating-count">({ratingCount} ratings)</div>
    </div>
  );
};

