import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { deleteAProduct, getProducts } from "../features/product/productSlice";
import { Link } from "react-router-dom";
import CustomModal from "../components/CustomModal";
import Content from "../components/Content";
import { toast } from "react-toastify";

const columns = [
  {
    title: "SNo",
    dataIndex: "key",
  },
  {
    title: "Title",
    dataIndex: "title",
    sorter: (a, b) => a.title.length - b.title.length,
  },
  {
    title: "Brand",
    dataIndex: "brand",
    sorter: (a, b) => a.brand.length - b.brand.length,
  },
  {
    title: "Category",
    dataIndex: "category",
    sorter: (a, b) => a.category.length - b.category.length,
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
  },
  {
    title: "Price",
    dataIndex: "price",
    sorter: (a, b) => a.price - b.price,
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];
let list =[]
const Productlist = () => {
  const [open, setOpen] = useState(false);
  const [productId, setproductId] = useState("");

  const showModal = (e) => {
    setOpen(true);
    setproductId(e);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProducts());
  }, []);
 
  const productState = useSelector((state) => state.product.products);
  if(productState.length!==0){
    list=productState
  }
 

  const data1 = list?.map((product, index) => ({
    key: index + 1,
    title: product.title,
    brand: product?.brandDetails?.title,
    category: product?.categoryDetails?.title,
    quantity: product.quantity,
    price: `${product.price}`,
    action: (
      <>
        <Link
          to={`/admin/product/${product?._id}`}
          className="fs-3 text-success"
        >
          <BiEdit />
        </Link>
        <button
          className="ms-3 fs-3 text-danger bg-transparent border-0"
          onClick={() => showModal(product?._id)}
        >
          <AiFillDelete />
        </button>
      </>
    ),
  }));

  const deleteProduct = async (e) => {
    try {
      await dispatch(deleteAProduct(e));
      await dispatch(getProducts());
      toast.success("Product deleted Successfullly");
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Content>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4 className="title">Products</h4>
        {/* <div className="">
          <button className="btn btn-primary border-0 rounded-3 my-3">
            Upload Pdf
          </button>
        </div> */}
      </div>
      <div>
        <Table columns={columns} dataSource={data1} />
      </div>
      <CustomModal
        hideModal={hideModal}
        open={open}
        performAction={() => deleteProduct(productId)}
        title="Are you sure you want to delete this Product?"
      />
    </Content>
  );
};

export default Productlist;
