import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";

export const getSuppliers = async () => {
  const response = await axios.get(`${base_url}supplier`);
  return response.data;
};

export const createSupplier = async (supplier) => {
  const response = await axios.post(`${base_url}supplier`, supplier, config);
  return response.data;
};

export const getSupplier = async (id) => {
  const response = await axios.get(`${base_url}supplier/${id}`, config);
  return response.data;
};

export const updateSupplier = async (id, payload) => {
  const response = await axios.put(
    `${base_url}supplier/${id}`,
    payload,
    config
  );
  return response.data;
};

export const deleteSupplier = async (id) => {
  const response = await axios.delete(`${base_url}supplier/${id}`, config);
  return response.data;
};
