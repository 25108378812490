import React, { useState } from "react";
import { Modal } from "antd";
import { toast } from "react-toastify";


const CustomModal = (props) => {
  const { open, hideModal, performAction, title, width } = props;


  const [messageText, setMessageText] = useState("");

  const handleOk = () => {
    if (!messageText.trim()) {
      toast.error("Please enter a reason for deletion.");
      return;
    }
    performAction(messageText);
    setMessageText("");
  };
  return (
    <Modal
      // title="Confirmation"
      centered
      open={open}
      onOk={handleOk}
      onCancel={hideModal}
      okText="Delete"
      cancelText="Cancel"
      width={width || 600}
      className="custom-modal"
    >
      <p>{title}</p>
      <div className="col-12 form-group">
        <label className='lablesHeading'>Reason <span className="text-danger">*</span></label>
        <div>
          <textarea
            className="w-100 rounded"
            rows={4}
            placeholder="Enter the reason for deletion"
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            required />
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
