import React, { useState, useEffect } from "react";
import Content from "../components/Content";
import { getProfessionals } from "./../features/professionals/ProfessionalService";
import Loader from "../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import RatingModel from "./RatingModel";
import DeleteModal from "../components/CustomModal";
import { DeleteCustomer } from "../features/customers/CustomerService";
import DeleteModel from "../components/DeleteModel";
import TableContainer from "../components/TableContainer/TableContainer";
import { MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import UserAvatar from "../components/UserAvatar/UserAvatar";
import Pagination from "../components/Pagination/Pagination";
import { paginateData } from "../utils/utils";
import { IoEyeOutline } from "react-icons/io5";
import LocationViewModal from "../components/LocationViewModal/LocationViewModal";
import { statusChange } from "../services/apis";
import { Modal, ModalBody } from "reactstrap";
import DropDownMenu from "../components/DropDownMenu/DropDownMenu";
import { BsThreeDotsVertical } from "react-icons/bs";


const Professionals = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [profData, setProfData] = useState([]);
  const [page, setPage] = useState(0);
  const rowsPerPage = 5
  const [data, setData] = useState([])
  const [allData, setAllData] = useState([])
  const [allData2, setAllData2] = useState([])
  const [noData, setNoData] = useState(false)

  const [locationModal, setLocationModal] = useState(false)
  const [location, setLocation] = useState({
    lat: null,
    lng: null
  })

  const [docId, setDocId] = useState('')
  const [reasonForSuspend, setReasonForSuspend] = useState('')
  const [statusModal, setStatusModal] = useState(false)
  const [search, setSearch] = useState('')


  useEffect(() => {
    let slicedData = paginateData(page, rowsPerPage, allData)
    setData(slicedData)
  }, [page, rowsPerPage])



  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await getProfessionals();
      const formattedData = response?.map((item, index) => {
        return {
          ...item,
          name: `${item?.prefix?item?.prefix:"-"}${item?.firstname?item?.firstname:"--"}`,
        };
      });
      if (formattedData?.length == 0) {
        setNoData(true)
      } else {
        setNoData(false)
      }
      console.log(formattedData);
      setAllData(formattedData);
      setAllData2(formattedData);
      let slicedData = paginateData(page, rowsPerPage, formattedData)
      setData(slicedData)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false);
    }

  };

  useEffect(() => {
    fetchData();
  }, []);
  async function deleteCustomer() {
    setIsLoading(true)
    try {
      await DeleteCustomer(deleteId);
      setOpenModal1(false)
      await fetchData()
    } catch (err) {
      console.error(err);
    } finally {

      setIsLoading(false)
    }
  }


  function toggle() {
    setLocationModal(false)
    setLocation({
      lat: null,
      lng: null
    })

  }


  function toggleModal() {
    setStatusModal(false)
    setDocId('')
    setReasonForSuspend('')
  }

  async function changeStatus2(e) {
    e.preventDefault()
    setIsLoading(true)
    let payload = {
      "status": 'disapproved',
      "reason": reasonForSuspend
    }
    try {
      await statusChange(docId, payload)
      await fetchData()
      toggleModal()
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  function searchData(e) {
    let value = e?.toLowerCase()
    setSearch(e)
    let arr = [...allData2]
    if (e == '') {
      setAllData(allData2)
      let slicedData = paginateData(page, rowsPerPage, allData2)
      setData(slicedData)
      if (slicedData.length == 0) {
        setNoData(true)
      } else {
        setNoData(false)
      }
    } else {
      let newArr = arr?.filter(res => res?.name?.toLowerCase()?.includes(value))
      console.log(newArr)
      setAllData(newArr)
      let slicedData = paginateData(page, rowsPerPage, newArr)
      setData(slicedData)
      if (slicedData.length == 0) {
        setNoData(true)
      } else {
        setNoData(false)
      }
    }

  }

  function changeStatusUi(status) {
    switch (status) {
      case 'disapproved':
        return <div className="statusOuterin">
          <div className="statusDotin" />
          <div className="Statusin fs-6">Disapproved</div>
        </div>
        break;
      case 'under review':
        return <div className="statusOuterr">
          <div className="statusDotr" />
          <div className="Statusr fs-6">Under Review</div>
        </div>
        break;
      case 'approved':
        return <div className="statusOuterA">
          <div className="statusDotA" />
          <div className="StatusA fs-6">Approved</div>
        </div>
        break;

      default:
        break;
    }
  }


  function statusData(key){
    console.log(key)
    switch (key) {
      case "succeeded":
        return <div className="text-success">succeeded</div>
        break;
      case "failed":
        return <div className="text-danger">failed</div>
        break;
      case "pending":
        return <div className="text-warning">pending</div>
        break;
    
      default:
        return '--'
        break;
    }
  }



  return (
    <Content>
      {isLoading ? <Loader open={isLoading} /> : ""}
      <LocationViewModal
        open={locationModal}
        toggle={toggle}
        location={location}
      />
      <Modal isOpen={statusModal} centered toggle={toggleModal}>
        <ModalBody>
          <form className="mt-3" onSubmit={changeStatus2}>
            <label className="form-label fw-bold">Enter Reason</label>
            <textarea
              className="form-control"
              rows={5}
              placeholder="Enter your reason here"
              value={reasonForSuspend}
              onChange={(e) => {
                if (e.target.value == " ") {
                  e.target.value = "";
                } else {
                  setReasonForSuspend(e.target.value);
                }
              }}
            ></textarea>
            <div className="d-flex justify-content-end gap-3 mt-3">
              <button
                className="btn btn-sm border"
                type="button"
                onClick={toggleModal}
              >
                Cancel
              </button>
              <button className="btn btn-sm btn-primary" type="submit">
                Submit
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
      <div>
        <h2 className="mb-4">Professionals</h2>
        <div className="row justify-content-md-between justify-content-sm-between justify-content-md-start mt-3">
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <input
              type="search"
              className="form-control w-100"
              placeholder="Search professional by name"
              value={search}
              onChange={(e) => {
                if (e.target.value == " ") {
                  e.target.value = "";
                } else {
                  searchData(e.target.value);
                }
              }}
            />
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 d-flex justify-content-end mt-md-0 mt-sm-0 mt-2"></div>
        </div>
        <div className="mt-4">
          <TableContainer>
            <Table>
              {noData ? (
                <caption className="text-center">No Data Found</caption>
              ) : (
                ""
              )}
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "2%" }}></TableCell>
                  <TableCell className="fw-bold">Name</TableCell>
                  <TableCell className="fw-bold">Organization Name</TableCell>
                  <TableCell className="fw-bold">Country</TableCell>
                  <TableCell className="fw-bold">Phone Number</TableCell>
                  <TableCell className="fw-bold">Email</TableCell>
                  <TableCell className="fw-bold">Payment Status</TableCell>
                  {/* <TableCell>Email</TableCell> */}
                  <TableCell className="fw-bold">Status</TableCell>
                  <TableCell className="fw-bold">Location</TableCell>
                  <TableCell className="fw-bold"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((res, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell
                        className="pointer"
                        onClick={() => navigate(`view/${res?._id}`)}
                      >
                        <UserAvatar
                          // name={res?.firstname}
                          img={res?.profile?.fileUrl}
                        />
                      </TableCell>
                      <TableCell
                        onClick={() => navigate(`view/${res?._id}`)}
                        className="text-capitalize pointer"
                      >
                        {res?.name}
                      </TableCell>
                      <TableCell className="text-capitalize">
                        {res?.company?res?.company:'---'}
                      </TableCell>
                      <TableCell className="text-capitalize">
                        {res?.country?res?.country:"---"}
                      </TableCell>
                      <TableCell className="text-capitalize">
                        {res?.mobile?res?.mobile:"---"}
                      </TableCell>
                      <TableCell className="text-capitalize">{res?.email}</TableCell>
                      <TableCell className="text-capitalize">
                        {res?.payment_details?.length !== 0 ? (
                          statusData(res?.payment_details[0]?.paymentStatus)
                        ) : (
                          <div className="text-warning">Pending</div>
                        )}
                      </TableCell>
                      {/* <TableCell className=''>{res?.email}</TableCell> */}
                      <TableCell className="text-capitalize">
                        {changeStatusUi(res?.profile_status)}
                      </TableCell>
                      <TableCell className="text-capitalize">
                        <button
                          className="btn p-0"
                          onClick={() => {
                            setLocation({
                              lat: res?.lat_long?.lat || null,
                              lng: res?.lat_long?.long || null,
                            });
                            setLocationModal(true);
                          }}
                        >
                          <IoEyeOutline className="fs-5" />
                        </button>
                      </TableCell>
                      <TableCell className="text-capitalize">
                        <DropDownMenu
                          button={
                            <button className="btn btn-sm">
                              <BsThreeDotsVertical className="fs-5" />
                            </button>
                          }
                        >
                          <MenuItem
                            onClick={() =>
                              navigate(`edit/${res?._id}`, { state: res })
                            }
                          >
                            Edit
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setOpenModal1(true);
                              setDeleteId(res?._id);
                            }}
                          >
                            Delete
                          </MenuItem>
                        </DropDownMenu>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Pagination
              allData={allData}
              currentPage={page}
              paginate={(d) => {
                setPage(d);
              }}
              itemPerPage={rowsPerPage}
            />
          </TableContainer>
        </div>
      </div>
      <RatingModel
        open={openModal}
        onClose={() => setOpenModal(false)}
        data={modalData}
      />
      <DeleteModel
        open={openModal1}
        hideModal={() => setOpenModal1(false)}
        title={`Are you sure you want to delete this User?`}
        performAction={deleteCustomer}
      />
    </Content>
  );
};

export default Professionals;
