import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Content from "../components/Content";
import CustomInput from "../components/CustomInput";
import { RxCross2 } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { uploadImg, delImg } from "../features/upload/uploadSlice";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getSupplier,
  createSupplier,
  updateSupplier,
} from "../features/supplier/supplierService";
import { base_url } from "../utils/baseUrl";
import { config } from "../utils/axiosconfig";
import axios from "axios";

let schema = yup.object().shape({
  supplierName: yup.string().required("Supplier Name is Required"),
  vendorCode: yup.string().required("Vendor Code is Required"),
  description: yup.string().required("Description is Required"),
});

const Supplier = () => {
  const [previewImage, setPreviewImage] = useState(null);
  const [img, setImg] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [info, setInfo] = useState({});
  const [check, setCheck] = useState(false);
  const [imgID, setImgID] = useState();
  const location = useLocation();
  const sID = location.pathname.split("/")[3];

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const response = await getSupplier(sID);
        setInfo(response);
        setImgID([response.images[0].fileName]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchInfo();
  }, [sID]);

  useEffect(() => {
    if (info.images && info.images.length > 0) {
      setPreviewImage(info.images[0].fileUrl);
    }
  }, [info]);

  useEffect(() => {
    if (sID === undefined) {
      setInfo("");
      setPreviewImage("");
      setImg("");
    }
  }, [sID]);

  const formik = useFormik({
    initialValues: {
      supplierName: info.supplierName || "",
      vendorCode: info.vendorCode || "",
      description: info.description || "",
      images: info.images || null,
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        if (sID != undefined) {
          if (check) {
            handleDeleteImage(imgID);
            const imageUrl = await handleImageUpload();
            const formData = {
              ...values,
              images: imageUrl?.payload?.data,
            };
            const response = await updateSupplier(sID, formData);
            if (response) {
              toast.success("Supplier Updated Successfullly!");
              setCheck(false);
              navigate("/admin/supplierList");
            }
          } else {
            const response = await updateSupplier(sID, values);
            if (response) {
              toast.success("Supplier Updated Successfully!");
              setCheck(false);
              navigate("/admin/supplierList");
            }
          }
        } else {
          if (values.images) {
            const imageUrl = await handleImageUpload();
            const formData = {
              ...values,
              images: imageUrl?.payload?.data,
            };
            const response = await createSupplier(formData);
            if (response) {
              toast.success("Supplier Added Successfullly!");
              navigate("/admin/supplierList");
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleDeleteImage = async (imgID) => {
    let payload = { data: imgID };
    try {
      const response = await axios.post(
        `${base_url}uploadTo/deleteMultipleDocData`,
        payload,
        config
      );
      formik.setFieldValue("images", []);
      setPreviewImage(null);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveImage = () => {
    formik.setFieldValue("images", null);
    setPreviewImage(null);
    if (sID != undefined) {
      setCheck(true);
    } else {
      setCheck(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    setImg([file]);
    formik.setFieldValue("images", file);
    setPreviewImage(URL.createObjectURL(file));
  };

  const handleImageUpload = async () => {
    try {
      const imageUrl = await dispatch(uploadImg(img));
      return imageUrl;
    } catch (error) {
      console.error("Image upload failed:", error);
      throw error;
    }
  };

  return (
    <Content>
      <div>
        <h4 className="mb-4 title">
          {sID !== undefined ? "Edit" : "Add"} Supplier
        </h4>
        <form onSubmit={formik.handleSubmit}>
          <CustomInput
            type="text"
            label="Enter Supplier Name"
            id="supplierName"
            name="supplierName"
            onChng={formik.handleChange}
            onBlr={formik.handleBlur}
            val={formik.values.supplierName}
          />
          {formik.touched.supplierName && formik.errors.supplierName ? (
            <div className="error">{formik.errors.supplierName}</div>
          ) : null}

          <CustomInput
            type="text"
            label="Enter Vendor code"
            id="vendorCode"
            name="vendorCode"
            onChng={formik.handleChange}
            onBlr={formik.handleBlur}
            val={formik.values.vendorCode}
          />

          <div className="form-floating mt-3">
            <textarea
              style={{ height: "13vh" }}
              className="form-control"
              id="description"
              placeholder="Description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              rows={4}
              cols={50}
            />
            <label htmlFor="description">Enter Description</label>
          </div>

          <div className="mt-3">
            <label htmlFor="images" className="form-label">
              {sID !== undefined ? "Edit" : "Add"} Supplier Icon
            </label>
            <div className="p-4 d-flex align-items-center justify-content-center bg-light">
              {previewImage ? (
                <div className=" position-relative">
                  <div>
                    <img
                      src={previewImage}
                      alt="Preview"
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                    />
                    <span
                      className="position-absolute text-danger top-0 end-0 rounded-circle "
                      style={{ cursor: "pointer" }}
                      onClick={handleRemoveImage}
                    >
                      <RxCross2 size={22} />
                    </span>
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-center flex-column">
                  <p className="text-muted mb-2">Drop files here to upload</p>
                  <label htmlFor="images" className="btn btn-primary">
                    Upload file
                    <input
                      type="file"
                      id="images"
                      name="images"
                      className="d-none"
                      onChange={handleFileChange}
                      accept="image/*"
                    />
                  </label>
                </div>
              )}
            </div>
          </div>
          {formik.touched.images && formik.errors.images ? (
            <div className="error">{formik.errors.images}</div>
          ) : null}

          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary border-0 rounded-3 my-5"
              type="submit"
            >
              {sID !== undefined ? "Update" : "Add"} Supplier
            </button>
          </div>
        </form>
      </div>
    </Content>
  );
};

export default Supplier;
