import { React, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useLocation, useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import { Formik, Field, FieldArray, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getBrands } from "../features/brand/brandSlice";
import { getCategories } from "../features/pcategory/pcategorySlice";
import { getColors } from "../features/color/colorSlice";
import { Select } from "antd";
import Dropzone from "react-dropzone";
import { delImg, uploadImg } from "../features/upload/uploadSlice";
import Loader from "../components/Loader/Loader";
import {
  createProducts,
  getAProduct,
  resetState,
  updateAProduct,
} from "../features/product/productSlice";
import Content from "../components/Content";
import CustomInput from "./../components/CustomInput";
import { getManufacturers } from "../features/Manufacturer/ManufacturerService";
import Manufacturer from "./Manufacturer";
import Supplier from "./Supplier";
import { getSuppliers } from "../features/supplier/supplierService";

import { Tabs } from "antd";
import { AddNewProduct } from "./AddNewProduct";

let schema = yup.object().shape({
  title: yup.string().required("Title is Required"),
  description: yup.string().required("Description is Required"),
  price: yup.number().required("Price is Required"),
  brand: yup.string().required("Brand is Required"),
  category: yup.string().required("Category is Required"),
  manufacturer: yup.string().required("Manufacturer is Required"),
  supplier: yup.string().required("Supplier is Required"),
  materialType: yup.string().required("Material Type is Required"),
  materialName: yup.string().required("Material name is Required"),
  waterContent: yup.string().required("Water Content is Required"),
  oxygenTransmissibility: yup
    .string()
    .required("Oxygen Transmissibility is Required"),
  baseCurve: yup.string().required("Base Curve is Required"),
  powerAxis: yup.string().required("Power axis is Required"),
  powerSpherical: yup.string().required("Power spherical is Required"),
  powerCyl: yup.string().required("Power cyl is Required"),
  powerAdd: yup.string().required("Power add is Required"),
  packSize: yup.string().required("Pack size is Required"),
  subTitle: yup.string().required("SUb-title is Required"),
  usageModal: yup.string().required("Usage modal is Required"),

  color: yup
    .array()
    .min(1, "Pick at least one color")
    .required("Color is Required"),
  quantity: yup.number().required("Quantity is Required"),
});

const Addproduct = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const getProductId = location.pathname.split("/")[3];
  const navigate = useNavigate();
  const [color, setColor] = useState([]);
  const [images, setImages] = useState([]);
  const [manfData, setManfData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    dispatch(getBrands());
    dispatch(getCategories());
    dispatch(getColors());
  }, []);

  const brandState = useSelector((state) => state.brand.brands);
  const catState = useSelector((state) => state.pCategory.pCategories);
  const colorState = useSelector((state) => state.color.colors);
  const imgState = useSelector((state) => state?.upload?.images);
  const newProduct = useSelector((state) => state.product);

  const {
    isSuccess,
    isError,
    isLoading,
    createdProduct,
    updatedProduct,
    productName,
    productDesc,
    productPrice,
    productBrand,
    productCategory,
    productManufacturer,
    productSupplier,
    productTag,
    productColors,
    productQuantity,
    productImages,
    productMaterialType,
    productMaterialName,
    productWaterContent,
    productOxygenTransmissibility,
    productBaseCurve,
    productPowerSpherical,
    productPowerCyl,
    productPowerAxis,
    productPowerAdd,
    productPackSize,
    productSubTitle,
    productUsageModal,
  } = newProduct;

  console.log(newProduct, "at");

  useEffect(() => {
    if (getProductId !== undefined) {
      dispatch(getAProduct(getProductId));
    } else {
      dispatch(resetState());
    }
  }, [getProductId]);

  useEffect(() => {
    if (isSuccess && createdProduct) {
      toast.success("Product Added Successfullly!");
      navigate("/admin/list-product");
    }
    if (isSuccess && updatedProduct) {
      toast.success("Product Updated Successfullly!");
      navigate("/admin/list-product");
    }
    if (isError) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, isError, isLoading, updatedProduct]);

  const coloropt = [];
  colorState.forEach((i) => {
    coloropt.push({
      label: (
        <div className="d-flex align-items-center">
          <div
            style={{
              width: "20px",
              height: "20px",
              // marginBottom: "10px",
              backgroundColor: i.title,
              borderRadius: "50%",
              listStyle: "none",
              border: "2px solid transparent",
              marginRight: "10px", // Add spacing between color and name
            }}
          ></div>
          <span>{i.title}</span> {/* Display color name */}
        </div>
      ),
      value: i._id,
    });
  });

  const productcolor = [];
  productColors?.forEach((i) => {
    productcolor.push({
      label: (
        <div className="col-3">
          <ul
            className="colors ps-0"
            style={{
              width: "20px",
              height: "20px",
              marginBottom: "10px",
              backgroundColor: i.title,
              borderRadius: "50%", // Added inline style for rounded shape
              listStyle: "none", // Hide bullet points
              border: "2px solid transparent",
            }}
          ></ul>
        </div>
      ),
      value: i._id,
    });
  });

  let img = [];
  imgState?.data?.forEach((i) => {
    img?.push({
      fileName: i?.fileName,
      fileUrl: i?.fileUrl,
    });
  });

  let [imgshow, setImgShow] = useState([]);
  useEffect(() => {
    if (location.pathname.includes("/admin/product")) {
      setImgShow([]);
    }
  }, [location.pathname]);

  useEffect(() => {
    console.log("at the time of add--");
    if (productImages?.length !== 0) {
      productImages?.forEach((i) => {
        imgshow.push({
          fileName: i?.fileName,
          fileUrl: i?.fileUrl,
        });
      });
    }
  }, [productImages]);

  useEffect(() => {
    formik.values.color = color ? color : " ";
    formik.values.images = img;
  }, [color, img]);

  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await getManufacturers();
      setManfData(response);
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData2 = async () => {
    setLoader(true);
    try {
      const response = await getSuppliers();
      setSupplierData(response);
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };
  useEffect(() => {
    fetchData2();
  }, []);

  const validationSchemaCombine = yup.object({
    ...schema.fields,
  });

  const formik = useFormik({
    initialValues: {
      title: productName || "",
      description: productDesc || "",
      // price: productPrice || "",
      brand: productBrand || "",
      category: productCategory || "",
      manufacturer: productManufacturer || "",
      supplier: productSupplier || "",
      // tags: productTag || "",
      color: productColors || "",
      materialType: productMaterialType || "",
      materialName: productMaterialName || "",
      waterContent: productWaterContent || "",
      oxygenTransmissibility: productOxygenTransmissibility || "",
      baseCurve: productBaseCurve || "",
      powerSpherical: productPowerSpherical || "",
      powerCyl: productPowerCyl || "",
      powerAxis: productPowerAxis || "",
      powerAdd: productPowerAdd || "",
      packSize: productPackSize || "",
      subTitle: productSubTitle || "",
      usageModal: productUsageModal || "",
      quantity: productQuantity || "",
      images: productImages || "",

      basePriceIndia: "",
      basePriceUAE: "",
      sellingPriceUAE: "",
      sellingPriceIndia: "",
      stockIndia: "",
      stockUAE: "",

      countryDetails: [
        {
          country: "",
          basePrice: 0,
          sellingPrice: 0,
          currency: "",
          stock: 0,
        },
        {
          country: "",
          basePrice: 0,
          sellingPrice: 0,
          currency: "",
          stock: 0,
        },
      ],
    },

    // enableReinitialize: true,
    validationSchema: validationSchemaCombine,
    onSubmit: (values) => {
      console.log(values);

      if (getProductId !== undefined) {
        const data = { id: getProductId, productData: values };
        dispatch(updateAProduct(data));
      } else {
        if (formik.values.basePriceIndia || formik.values.basePriceUAE) {
          const countryDetails = [];
          if (formik.values.basePriceIndia) {
            countryDetails.push({
              country: "INDIA",
              basePrice: formik.values.basePriceIndia,
              sellingPrice:
                formik.values.sellingPriceIndia || formik.values.basePriceIndia,
              currency: "INR",
              stock: formik.values.stockIndia,
            });
          }
          if (formik.values.basePriceUAE) {
            countryDetails.push({
              country: "UAE",
              basePrice: formik.values.basePriceUAE,
              sellingPrice:
                formik.values.sellingPriceUAE || formik.values.basePriceUAE,
              currency: "AED",
              stock: formik.values.stockUAE,
            });
          }
        }

        dispatch(createProducts(values));
        formik.resetForm();
        setImgShow([]);
        setColor(null);
        setTimeout(() => {
          dispatch(resetState());
        }, 3000);
      }
    },
  });

  const handleColors = (e) => {
    setColor(e);
  };

  useEffect(() => {
    formik.setValues({
      title: productName || "",
      description: productDesc || "",
      price: productPrice || "",
      brand: productBrand || "",
      category: productCategory || "",
      manufacturer: productManufacturer || "",
      supplier: productSupplier || "",
      // tags: productTag || "",
      color: productColors || [],
      quantity: productQuantity || "",
      images: productImages || [],
    });
    setColor(productColors);
  }, [
    productName,
    productDesc,
    productPrice,
    productBrand,
    productCategory,
    productTag,
    productColors,
    productQuantity,
    productImages,
    productManufacturer,
    productSupplier,
  ]);

  const handleDeleteImage = (fileName) => {
    dispatch(delImg(fileName)).then(() => {
      const updatedImages = imgshow.filter((img) => img?.fileName !== fileName);
      imgshow.length = 0;
      setImgShow(updatedImages);
      formik.setFieldValue("images", [...updatedImages]);
    });
  };

  const items = [
    {
      key: "1",
      label: "UAE",
      children: (
        <div className="row mt-2">
          <div className="col-md-6 col-sm-6 col-12">
            <CustomInput
              type="number"
              label="Base Price (UAE)"
              name="basePriceUAE"
              onChng={formik.handleChange("basePriceUAE")}
              onBlr={formik.handleBlur("basePriceUAE")}
              val={formik.values.basePriceUAE}
            />
            <div className="error mt-2 fs-6">
              {formik.touched.basePriceUAE && formik.errors.basePriceUAE}
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-12">
            <CustomInput
              type="number"
              label="Selling Price (UAE)"
              name="sellingPriceUAE"
              onChng={formik.handleChange("sellingPriceUAE")}
              onBlr={formik.handleBlur("sellingPriceUAE")}
              val={formik.values.sellingPriceUAE}
            />
            <div className="error mt-2 fs-6">
              {formik.touched.sellingPriceUAE && formik.errors.sellingPriceUAE}
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-12">
            <CustomInput
              type="number"
              label="Stock (UAE)"
              name="stockUAE"
              onChng={formik.handleChange("stockUAE")}
              onBlr={formik.handleBlur("stockUAE")}
              val={formik.values.stockUAE}
            />
            <div className="error mt-2 fs-6">
              {formik.touched.stockUAE && formik.errors.stockUAE}
            </div>
          </div>
        </div>
      ),
    },

    {
      key: "2",
      label: "India",
      children: (
        <div className="row mt-2">
          <div className="col-md-6 col-sm-6 col-12">
            <CustomInput
              type="number"
              label="Base Price (India)"
              name="basePriceIndia"
              onChng={formik.handleChange("basePriceIndia")}
              onBlr={formik.handleBlur("basePriceIndia")}
              val={formik.values.basePriceIndia}
            />
            <div className="error mt-2 fs-6">
              {formik.touched.basePriceIndia && formik.errors.basePriceIndia}
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-12">
            <CustomInput
              type="number"
              label="Selling Price (India)"
              name="sellingPriceIndia"
              onChng={formik.handleChange("sellingPriceIndia")}
              onBlr={formik.handleBlur("sellingPriceIndia")}
              val={formik.values.sellingPriceIndia}
            />
            <div className="error mt-2 fs-6">
              {formik.touched.sellingPriceIndia &&
                formik.errors.sellingPriceIndia}
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-12">
            <CustomInput
              type="number"
              label="Stock (India)"
              name="stockIndia"
              onChng={formik.handleChange("stockIndia")}
              onBlr={formik.handleBlur("stockIndia")}
              val={formik.values.stockIndia}
            />
            <div className="error mt-2 fs-6">
              {formik.touched.stockIndia && formik.errors.stockIndia}
            </div>
          </div>
        </div>
      ),
    },
  ];
  return (
    <Content>
      <AddNewProduct />
    </Content>
  );
  return (
    <Content>
      <Loader open={loader}></Loader>
      <h4 className="mb-4 title">
        {getProductId !== undefined ? "Edit" : "Add"} Product
      </h4>
      <div>
        <form
          onSubmit={formik.handleSubmit}
          className="d-flex gap-2 flex-column"
        >
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <CustomInput
                type="text"
                label="Enter product title"
                name="title"
                onChng={formik.handleChange("title")}
                onBlr={formik.handleBlur("title")}
                val={formik.values.title}
              />
              <div className="error mt-2 fs-6">
                {formik.touched.title && formik.errors.title}
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-12">
              <CustomInput
                type="text"
                label="Enter sub-title"
                name="subTitle"
                onChng={formik.handleChange("subTitle")}
                onBlr={formik.handleBlur("subTitle")}
                val={formik.values.subTitle}
              />
              <div className="error mt-2 fs-6">
                {formik.touched.subTitle && formik.errors.subTitle}
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6 col-sm-6 col-12">
              <select
                name="brand"
                loading
                onChange={formik.handleChange("brand")}
                onBlur={formik.handleBlur("brand")}
                value={formik.values.brand}
                className="form-select py-3 mb-3"
                id=""
              >
                <option value="">Select Brand</option>
                {brandState.map((i, j) => {
                  return (
                    <option key={j} value={i._id}>
                      {i.title}
                    </option>
                  );
                })}
              </select>
              <div className="error mt-2 fs-6">
                {formik.touched.brand && formik.errors.brand}
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-12">
              <select
                name="category"
                onChange={formik.handleChange("category")}
                onBlur={formik.handleBlur("category")}
                value={formik.values.category}
                className="form-select py-3 mb-3"
                id=""
              >
                <option value="">Select Category</option>
                {catState.map((i, j) => {
                  return (
                    <option key={j} value={i._id}>
                      {i.title}
                    </option>
                  );
                })}
              </select>
              <div className="error mt-2 fs-6">
                {formik.touched.category && formik.errors.category}
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-6 col-sm-6 col-12">
              <select
                name="manufacturer"
                className="form-select py-3 mb-3"
                id=""
                onChange={formik.handleChange("manufacturer")}
                onBlur={formik.handleBlur("manufacturer")}
                value={formik.values.manufacturer}
              >
                <option value="">Select Manufacturer</option>
                {manfData?.map((item, index) => {
                  return (
                    <option key={index} value={item?._id}>
                      {item?.manufacturerName}
                    </option>
                  );
                })}
              </select>
              <div className="error mt-2 fs-6">
                {formik.touched.manufacturer && formik.errors.manufacturer}
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-12">
              <select
                name="supplier"
                className="form-select py-3 mb-3"
                id=""
                onChange={formik.handleChange("supplier")}
                onBlur={formik.handleChange("supplier")}
                value={formik.values.supplier}
              >
                <option value="">Select Supplier</option>
                {supplierData?.map((item, index) => {
                  return (
                    <option key={index} value={item?._id}>
                      {item?.supplierName}
                    </option>
                  );
                })}
              </select>
              <div className="error mt-2 fs-6">
                {formik.touched.supplier && formik.errors.supplier}
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-6 col-sm-6 col-12 mt-3 ">
              <Select
                mode="multiple"
                allowClear
                className="form-multiselect w-100"
                placeholder="Select colors"
                value={color}
                // defaultValue={productcolor || color}
                onChange={(i) => {
                  handleColors(i);
                }}
                options={coloropt}
              />
              <div className="error mt-2 fs-6">
                {formik.touched.color && formik.errors.color}
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-12">
              <CustomInput
                type="number"
                label="Enter product quantity"
                name="quantity"
                onChng={formik.handleChange("quantity")}
                onBlr={formik.handleBlur("quantity")}
                val={formik.values.quantity}
              />
              <div className="error mt-2 fs-6">
                {formik.touched.quantity && formik.errors.quantity}
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-6 col-sm-6 col-12">
              <CustomInput
                type="text"
                label="Enter Material Name"
                name="materialName"
                onChng={formik.handleChange}
                onBlr={formik.handleBlur}
                val={formik.values.materialName}
              />
              <div className="error mt-2 fs-6">
                {formik.touched.materialName && formik.errors.materialName}
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-12">
              <CustomInput
                type="text"
                label="Enter material type"
                name="materialType"
                onChng={formik.handleChange("materialType")}
                onBlr={formik.handleBlur("materialType")}
                val={formik.values.materialType}
              />
              <div className="error mt-2 fs-6">
                {formik.touched.materialType && formik.errors.materialType}
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-6 col-sm-6 col-12">
              <CustomInput
                type="number"
                label="Enter water content"
                name="waterContent"
                onChng={formik.handleChange("waterContent")}
                onBlr={formik.handleBlur("waterContent")}
                val={formik.values.waterContent}
              />
              <div className="error mt-2 fs-6">
                {formik.touched.waterContent && formik.errors.waterContent}
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-12">
              <CustomInput
                type="text"
                label="Enter oxygen transmissibility"
                name="oxygenTransmissibility"
                onChng={formik.handleChange("oxygenTransmissibility")}
                onBlr={formik.handleBlur("oxygenTransmissibility")}
                val={formik.values.oxygenTransmissibility}
              />
              <div className="error mt-2 fs-6">
                {formik.touched.oxygenTransmissibility &&
                  formik.errors.oxygenTransmissibility}
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-6 col-sm-6 col-12">
              <CustomInput
                type="text"
                label="Enter base curve"
                name="baseCurve"
                onChng={formik.handleChange("baseCurve")}
                onBlr={formik.handleBlur("baseCurve")}
                val={formik.values.baseCurve}
              />
              <div className="error mt-2 fs-6">
                {formik.touched.baseCurve && formik.errors.baseCurve}
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-12">
              <CustomInput
                type="text"
                label="Enter power spherical"
                name="powerSpherical"
                onChng={formik.handleChange("powerSpherical")}
                onBlr={formik.handleBlur("powerSpherical")}
                val={formik.values.powerSpherical}
              />
              <div className="error mt-2 fs-6">
                {formik.touched.powerSpherical && formik.errors.powerSpherical}
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-6 col-sm-6 col-12">
              <CustomInput
                type="text"
                label="Enter power cyl"
                name="powerCyl"
                onChng={formik.handleChange("powerCyl")}
                onBlr={formik.handleBlur("powerCyl")}
                val={formik.values.powerCyl}
              />
              <div className="error mt-2 fs-6">
                {formik.touched.powerCyl && formik.errors.powerCyl}
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-12">
              <CustomInput
                type="text"
                label="Enter power axis"
                name="powerAxis"
                onChng={formik.handleChange("powerAxis")}
                onBlr={formik.handleBlur("powerAxis")}
                val={formik.values.powerAxis}
              />
              <div className="error mt-2 fs-6">
                {formik.touched.powerAxis && formik.errors.powerAxis}
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-6 col-sm-6 col-12">
              <CustomInput
                type="text"
                label="Enter power add"
                name="powerAdd"
                onChng={formik.handleChange("powerAdd")}
                onBlr={formik.handleBlur("powerAdd")}
                val={formik.values.powerAdd}
              />
              <div className="error mt-2 fs-6">
                {formik.touched.powerAdd && formik.errors.powerAdd}
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-12">
              <CustomInput
                type="text"
                label="Enter pack size"
                name="packSize"
                onChng={formik.handleChange("packSize")}
                onBlr={formik.handleBlur("packSize")}
                val={formik.values.packSize}
              />
              <div className="error mt-2 fs-6">
                {formik.touched.packSize && formik.errors.packSize}
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-6 col-sm-6 col-12">
              <select
                name="useageModad"
                className="form-select py-3 mt-3"
                id=""
                onChange={formik.handleChange("usageModal")}
                onBlur={formik.handleBlur("usageModal")}
                value={formik.values.usageModal}
              >
                <option value="">Select Usage Modal</option>

                <option value={"dailies"}>Dailies</option>
                <option value={"monthly"}>Monthly</option>
              </select>
              <div className="error mt-2 fs-6">
                {formik.touched.usageModal && formik.errors.usageModal}
              </div>
            </div>
          </div>

          {/* Country and Stock fields with FieldArray */}

          {/* <Tabs defaultActiveKey="1" items={items}></Tabs> */}

          {/* <FieldArray name="formik?.values?.countryDetails">
            {({ insert, remove, push }) => (
              <div>
                {formik?.values?.countryDetails?.map((res) => {
                  return <div>okokoko</div>;
                })}
              </div>
            )}
          </FieldArray> */}

          <div className="labelHeading">Description</div>
          <div className="">
            <ReactQuill
              style={{ height: "10rem " }}
              theme="snow"
              name="description"
              onChange={formik.handleChange("description")}
              value={formik.values.description}
            />
          </div>
          <div className="error mt-2 fs-6">
            {formik.touched.description && formik.errors.description}
          </div>

          <div className="labelHeading mt-5">Images</div>
          <div className="bg-white border-1 p-5 text-center border">
            <Dropzone
              onDrop={(acceptedFiles) => dispatch(uploadImg(acceptedFiles))}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()} style={{ cursor: "pointer" }}>
                    <input {...getInputProps()} />
                    <p>Click to Select Files</p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>

          {console.log(imgshow)}

          <div className="showimages d-flex flex-wrap gap-3">
            {imgshow?.map((i, j) => {
              return (
                <div className=" position-relative" key={j}>
                  <button
                    type="button"
                    onClick={() => handleDeleteImage(i.fileName)}
                    className="btn-close position-absolute"
                    style={{ top: "10px", right: "10px" }}
                  ></button>
                  <img src={i.fileUrl} alt="" width={200} height={200} />
                </div>
              );
            })}
            {imgState?.data?.map((i, j) => {
              return (
                <div className=" position-relative" key={j}>
                  <button
                    type="button"
                    onClick={() => handleDeleteImage(i.fileName)}
                    className="btn-close position-absolute"
                    style={{ top: "10px", right: "10px" }}
                  ></button>
                  <img src={i.fileUrl} alt="" width={200} height={200} />
                </div>
              );
            })}
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary  border-0 rounded-3 my-3"
              type="submit"
            >
              {getProductId !== undefined ? "Update" : "Add"} Product
            </button>
          </div>
        </form>
      </div>
    </Content>
  );
};

export default Addproduct;
