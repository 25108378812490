import React from "react";
import { Modal } from "antd";

const DeleteModel = (props) => {
  const { open, hideModal, performAction, title, width } = props;

  return (
    <Modal
      title="Confirmation"
      centered
      open={open}
      onOk={performAction}
      onCancel={hideModal}
      okText="Delete"
      cancelText="Cancel"
      width={width || 600}
      className="custom-modal"
    >
      <h3>{title}</h3>
    </Modal>
  );
};

export default DeleteModel;
