import axios from "axios";
import { base_url } from "../../utils/baseUrl";

export const getCustomers = async () => {
  const response = await axios.get(`${base_url}user/all-users`);
  return response.data;
};
export const UpdateCustomer = async (payload,config) => {
  const response = await axios.put(`${base_url}user/edit-user`,payload,config);
  return response.data;
};
export const DeleteCustomer = async (id) => {
  const response = await axios.delete(`${base_url}user/${id}`); // Use template literal for ID
  return response.data;
};
export const uploadDocuments = async (payload) => {
  const response = await axios.post(`${base_url}uploadTo/uploadDocuments`,payload); // Use template literal for ID
  return response.data;
};
export const DeleteDocuments = async (payload) => {
  const response = await axios.post(`${base_url}uploadTo/deleteMultipleDocData`,payload); // Use template literal for ID
  return response.data;
};

