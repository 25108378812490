import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";

const uploadImg = async (data) => {
  const response = await axios.post(
    `${base_url}uploadTo/uploadDocuments/`,
    data,
    config
  );
  return response.data;
};
const deleteImg = async (res,data) => {
  let payload = {data: data}
  console.log(data,'service')
  const response = await axios.post(
    `${base_url}uploadTo/deleteMultipleDocData`,
    payload,
    config
  );
  return response.data;
};

const uploadService = {
  uploadImg,
  deleteImg,
};

export default uploadService;
