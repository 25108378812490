import axios from "axios";
import { base_url } from "../../utils/baseUrl";

export const getProfessionals = async () => {
  const response = await axios.get(`${base_url}user/all-doctors`);
  return response.data;
};

export const updaterating = async (payload, config) => {
  const response = await axios.post(`${base_url}user/updateAdminRating`, payload, config);
  return response.data;
};

export const updateProfessional = async (payload, config) => {
  const response = await axios.put(`${base_url}user/editProfessional`, payload, config);
  return response.data;
};