import React, { useEffect, useState } from "react";
import { getAllPaymentData } from "../services/apis";
import Pagination from "../components/Pagination/Pagination";
import TableContainer from "../components/TableContainer/TableContainer";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { paginateData } from "../utils/utils";
import Content from "../components/Content";
import Loader from "../components/Loader/Loader";
import { Nav, NavItem, NavLink } from "reactstrap";

export default function PaymentListing() {
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const rowsPerPage = 5;
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [allData2, setAllData2] = useState([]);
  const [noData, setNoData] = useState(false);
  const [search, setSearch] = useState("");
  const [activeTab, toggleTab] = useState("0");

  async function getData(type) {
    try {
      setIsLoading(true);
      let res = await getAllPaymentData();
      let data = res?.data?.transactions;
     
      let data2 = data?.filter(res=>res?.paymentMethod == type)
       if (data2?.length == 0) {
         setNoData(true);
       } else {
         setNoData(false);
       }
      setAllData(data2);
      setAllData2(data2);
      let slicedData = paginateData(page, rowsPerPage, data2);
      setData(slicedData);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (activeTab == "0") {
      getData("strip");
    } else {
      getData("");
    }
  }, [activeTab]);

  useEffect(() => {
    let slicedData = paginateData(page, rowsPerPage, allData);
    setData(slicedData);
  }, [page, rowsPerPage]);

  function formatDate(isoString) {
    const date = new Date(isoString);

    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();

    const hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");

    const ampm = hours >= 12 ? "pm" : "am";
    const hours12 = hours % 12 || 12; // Convert 0 to 12 for 12 AM

    // Construct the formatted date string
    return `${day}-${month}-${year} ${hours12}:${minutes} ${ampm}`;
  }

  function statusData(key) {
    switch (key) {
      case "succeeded":
        return <div className="text-success">succeeded</div>;
        break;
      case "failed":
        return <div className="text-danger">failed</div>;
        break;
      case "pending":
        return <div className="text-warning">pending</div>;
        break;

      default:
        return "--";
        break;
    }
  }

  function searchData(e) {
    let value = e?.toLowerCase();
    setSearch(e);
    let arr = [...allData2];
    if (e == "") {
      setAllData(allData2);
      let slicedData = paginateData(page, rowsPerPage, allData2);
      setData(slicedData);
      if (slicedData.length == 0) {
        setNoData(true);
      } else {
        setNoData(false);
      }
    } else {
      let newArr = arr?.filter((res) =>
        res?.userId?.firstname?.toLowerCase()?.includes(value)
      );
      console.log(newArr);
      setAllData(newArr);
      let slicedData = paginateData(page, rowsPerPage, newArr);
      setData(slicedData);
      if (slicedData.length == 0) {
        setNoData(true);
      } else {
        setNoData(false);
      }
    }
  }

  return (
    <>
      <Content>
        {isLoading ? <Loader open={isLoading} /> : ""}
        <h2 className="mb-4">Payments</h2>

        <Nav
          vertical={false}
          className={`${true ? "border-bottom flex flex-nowrap" : ""}`}
        >
          <NavItem>
            <NavLink
              className={`text-secondary fw-bold ${
                activeTab == "0"
                  ? true
                    ? "activeTab2"
                    : "activeTab text-white "
                  : ""
              } ${
                true ? "px-md-4 px-sm-3 px-2" : "rounded ps-4 mt-2 py-2"
              } pointer`}
              onClick={() => toggleTab("0")}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div className="fs-6">Stripe Payments</div>{" "}
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`text-secondary fw-bold ${
                activeTab == "1"
                  ? true
                    ? "activeTab2"
                    : "activeTab text-white "
                  : ""
              } ${
                true ? "px-md-4 px-sm-3 px-2" : "rounded ps-4 mt-2 py-2"
              } pointer`}
              onClick={() => toggleTab("1")}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div className="fs-6">Cashfree Payments</div>
              </div>
            </NavLink>
          </NavItem>
        </Nav>
        <div className="row justify-content-md-between justify-content-sm-between justify-content-md-start mt-3">
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <input
              type="search"
              className="form-control w-100"
              placeholder="Search by name"
              value={search}
              onChange={(e) => {
                if (e.target.value == " ") {
                  e.target.value = "";
                } else {
                  searchData(e.target.value);
                }
              }}
            />
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 d-flex justify-content-end mt-md-0 mt-sm-0 mt-2"></div>
        </div>
        <div className="mt-4">
          <TableContainer>
            <Table>
              {noData ? (
                <caption className="text-center">No Data Found</caption>
              ) : (
                ""
              )}
              <TableHead>
                <TableRow>
                  <TableCell className="fw-bold">Name</TableCell>
                  <TableCell className="fw-bold">Amount</TableCell>
                  <TableCell className="fw-bold">Payment Method</TableCell>
                  <TableCell className="fw-bold">Transaction Id</TableCell>
                  <TableCell className="fw-bold">Payment Status</TableCell>
                  <TableCell className="fw-bold">Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((res, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell>{res?.userId?.firstname}</TableCell>
                      <TableCell>
                        {res?.paymentCurrency}&nbsp;
                        {res?.paymentAmount}
                      </TableCell>
                      <TableCell>{res?.paymentMethod}</TableCell>
                      <TableCell>{res?.transactionId}</TableCell>
                      <TableCell className="text-capitalize">
                        {statusData(res?.paymentStatus)}
                      </TableCell>
                      <TableCell>{formatDate(res?.paymentDate)}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Pagination
              allData={allData}
              currentPage={page}
              paginate={(d) => {
                setPage(d);
              }}
              itemPerPage={rowsPerPage}
            />
          </TableContainer>
        </div>
      </Content>
    </>
  );
}
