import React from 'react'
import Content from '../../components/Content'

const Appointmentsreports = () => {
    return (
        <Content>
            <div>
                <h4 className="mb-4 title">Appointments Reports</h4>
            </div>
        </Content>
    )
}

export default Appointmentsreports