import axios from "axios";
import { base_url } from "../utils/baseUrl";
import { config } from "../utils/axiosconfig";

export async function statusChange(id, payload) {
  return axios.post(
    `${base_url}user/approvalOrDisapproval/${id}`,
    payload,
    config
  );
}

export async function viewProfessional(id) {
  return axios.get(`${base_url}user/userById/${id}`);
}

export const updateAdminRatings = async (payload) => {
  return axios.post(`${base_url}user/updateAdminRating`, payload, config);
};

export const updateProfessionalData = async (payload) => {
  return axios.put(`${base_url}user/editProfessional`, payload, config);
};

export const getAllPaymentData = async () => {
  return axios.get(`${base_url}payment/transactions`);
};
