import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login";
import MainLayout from "./components/MainLayout";
import Enquiries from "./pages/Enquiries";
import Bloglist from "./pages/Bloglist";
import Blogcatlist from "./pages/Blogcatlist";
import Orders from "./pages/Orders";
import Customers from "./pages/Customers";
import Colorlist from "./pages/Colotlist";
import Categorylist from "./pages/Categorylist";
import Brandlist from "./pages/Brandlist";
import Productlist from "./pages/Productlist";
import Addblog from "./pages/Addblog";
import Addblogcat from "./pages/Addblogcat";
import Addcolor from "./pages/Addcolor";
import Addcat from "./pages/Addcat";
import Addbrand from "./pages/Addbrand";
import Addproduct from "./pages/Addproduct";
import Couponlist from "./pages/Couponlist";
import AddCoupon from "./pages/AddCoupon";
import ViewEnq from "./pages/ViewEnq";
import ViewOrder from "./pages/ViewOrder";
import Appointment from "./pages/Appointment";
import { OpenRoutes } from "./routing/OpenRoutes";
import { PrivateRoutes } from "./routing/PrivateRoutes";
import Supplier from "./pages/Supplier";
import InvoicePreview from "./pages/Invoices/InvoicePreview";
import SupplierList from "./pages/SupplierList";
import Manufacturer from "./pages/Manufacturer";
import ManufacturerList from "./pages/ManufacturerList";
import Reportsview from "./pages/Reports/Userreports";
import Usertab from "./pages/Usertab";
import Professionals from "./pages/Professionals";
import Aboutus from "./pages/Aboutus";
import Userreports from "./pages/Reports/Userreports";
import Appointmentsreports from "./pages/Reports/Appointmentsreports";
import AppointmentView from "./pages/AppointmentView";
import ProfessionalView from "./pages/ProfessionalView";
import ProfessionalEdit from "./pages/ProfessionalEdit";
import PaymentListing from "./pages/PaymentListing";
function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <OpenRoutes>
              <Login />
            </OpenRoutes>
          }
        />
        {/* <Route path="/reset-password" element={<Resetpassword />} />
        <Route path="/forgot-password" element={<Forgotpassword />} /> */}
        <Route
          path="/admin"
          element={
            <PrivateRoutes>
              <MainLayout />
            </PrivateRoutes>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="enquiries" element={<Enquiries />} />
          <Route path="enquiries/:id" element={<ViewEnq />} />
          <Route path="blog-list" element={<Bloglist />} />
          <Route path="blog" element={<Addblog />} />
          <Route path="blog/:id" element={<Addblog />} />
          <Route path="coupon-list" element={<Couponlist />} />
          <Route path="coupon" element={<AddCoupon />} />
          <Route path="coupon/:id" element={<AddCoupon />} />
          <Route path="blog-category-list" element={<Blogcatlist />} />
          <Route path="blog-category" element={<Addblogcat />} />
          <Route path="blog-category/:id" element={<Addblogcat />} />
          <Route path="orders" element={<Orders />} />
          <Route path="order/:id" element={<ViewOrder />} />
          <Route path="customers" element={<Customers />} />
          <Route path="professionals" element={<Professionals />} />
          <Route path="professionals/view/:id" element={<ProfessionalView />} />
          <Route path="professionals/edit/:id" element={<ProfessionalEdit />} />
          <Route path="list-color" element={<Colorlist />} />
          <Route path="color" element={<Addcolor />} />
          <Route path="color/:id" element={<Addcolor />} />
          <Route path="list-category" element={<Categorylist />} />
          <Route path="category" element={<Addcat />} />
          <Route path="category/:id" element={<Addcat />} />
          <Route path="list-brand" element={<Brandlist />} />
          <Route path="brand" element={<Addbrand />} />
          <Route path="brand/:id" element={<Addbrand />} />
          <Route path="list-product" element={<Productlist />} />
          <Route path="product" element={<Addproduct />} />
          <Route path="product/:id" element={<Addproduct />} />
          <Route path="appointments" element={<Appointment />} />
          <Route path="appointmentView" element={<AppointmentView />}></Route>
          <Route path="supplier" element={<Supplier />} />
          <Route path="supplier/:id" element={<Supplier />} />
          <Route path="manufacturer" element={<Manufacturer />} />
          <Route path="manufacturer/:id" element={<Manufacturer />} />
          <Route path="invoices" element={<InvoicePreview />} />
          <Route path="SupplierList" element={<SupplierList />} />
          <Route path="manufacturerList" element={<ManufacturerList />} />
          <Route path="Usertab" element={<Usertab />} />
          <Route path="userManagementReports" element={<Userreports />}></Route>
          <Route
            path="appointmentReports"
            element={<Appointmentsreports />}
          ></Route>
          <Route path="aboutus" element={<Aboutus />} />
          <Route path="paymentList" element={<PaymentListing />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
