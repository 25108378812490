import React, { useState } from "react";
import ReactQuill from "react-quill";
import Content from "../components/Content";
import CustomInput from "../components/CustomInput";
import { useFormik } from "formik";
import * as yup from "yup";

let schema = yup.object().shape({
  title: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),
});

const Aboutus = () => {
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      try {
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleDescriptionChange = (value) => {
    formik.setFieldValue("description", value);
  };

  const handleDescriptionBlur = () => {
    formik.setFieldTouched("description", true);
  };

  return (
    <Content>
      <h4 className="mb-4 title">About Us</h4>
      <form onSubmit={formik.handleSubmit}>
        <div className="row mt-1">
          <div className="col-12">
            <CustomInput
              type="text"
              label="Enter title"
              name="title"
              id="title"
              onBlr={formik.handleBlur}
              onChng={formik.handleChange("title")}
              val={formik.values.title}
            />
            {formik.touched.title && formik.errors.title ? (
              <div className="error">{formik.errors.title}</div>
            ) : (
              ""
            )}
          </div>
          <div className="mt-4 col-12">
            <ReactQuill
              style={{ height: "10rem" }}
              theme="snow"
              name="description"
              value={formik.values.description}
              onChange={handleDescriptionChange}
              onBlur={handleDescriptionBlur}
            />
            {formik.touched.description && formik.errors.description ? (
              <div className="error mt-5">{formik.errors.description}</div>
            ) : (
              ""
            )}
          </div>
          <div className="mt-3"></div>
        </div>

        <div className="d-flex justify-content-end mt-5">
          <button className="btn btn-primary border-0 rounded-3" type="submit">
            Add
          </button>
        </div>
      </form>
    </Content>
  );
};

export default Aboutus;
