import React, { useState, useEffect } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import {
  AiOutlineDashboard,
  AiOutlineShoppingCart,
  AiOutlineUser,
  AiOutlineBgColors,
  AiOutlineLogout,
  AiOutlineLayout,
} from "react-icons/ai";

import { CiDatabase } from "react-icons/ci";
import { MdContentPaste } from "react-icons/md";
import { LuUsers } from "react-icons/lu";
import { TbReportAnalytics } from "react-icons/tb";
import { CgProfile } from "react-icons/cg";
import { GrSchedules } from "react-icons/gr";
import { RiCouponLine } from "react-icons/ri";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { ImBlog } from "react-icons/im";
import { FaClipboardList } from "react-icons/fa";
import { SiBrandfolder, SiMakerbot } from "react-icons/si";
import { BiCategoryAlt } from "react-icons/bi";
import { MdOutlineSupport } from "react-icons/md";
import { Layout, Menu, theme } from "antd";
import { MdPayment } from "react-icons/md";

const { Header, Sider, Content } = Layout;

const MainLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1200) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Layout className="main-layout">
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        className="main-sider"
      >
        <div className="logo-container">
          <h2 className="text-white fs-5 text-center mb-0">
            <span className="sm-logo">
              <img
                style={{ height: "4rem" }}
                src="/eyeLogo.png"
                className="img-fluid"
                alt="Logo"
              />
            </span>
            <span className="lg-logo">
              <img
                style={{ height: "4rem" }}
                src="/eyeLogo.png"
                className="img-fluid"
                alt="Logo"
              />
            </span>
          </h2>
        </div>
        <Menu
          style={{ marginTop: "10px" }}
          theme="light"
          mode="inline"
          defaultSelectedKeys={[""]}
          onClick={({ key }) => {
            if (key === "signout") {
              localStorage.clear();
              window.location.reload();
            } else {
              navigate(key);
            }
          }}
          items={[
            {
              key: "",
              icon: <AiOutlineDashboard className="fs-5" />,
              label: "Dashboard",
            },
            {
              key: "users",
              icon: <AiOutlineUser className="fs-5" />,
              label: "Users",
              children: [
                {
                  key: "customers",
                  icon: <AiOutlineUser className="fs-5" />,
                  label: "Customers",
                },
                {
                  key: "professionals",
                  icon: <LuUsers className="fs-5" />,
                  label: "Professionals",
                },
              ],
            },
            // {
            //   key: "inventory",
            //   icon: <AiOutlineShoppingCart className="fs-5" />,
            //   label: "Inventory",
            //   children: [
            //     {
            //       key: "product",
            //       icon: <AiOutlineShoppingCart className="fs-5" />,
            //       label: "Add Product",
            //     },
            //     {
            //       key: "list-product",
            //       icon: <AiOutlineShoppingCart className="fs-5" />,
            //       label: "Product List",
            //     },
            //     {
            //       key: "brand",
            //       icon: <SiBrandfolder className="fs-5" />,
            //       label: "Brand",
            //     },
            //     {
            //       key: "list-brand",
            //       icon: <SiBrandfolder className="fs-5" />,
            //       label: "Brand List ",
            //     },
            //     {
            //       key: "category",
            //       icon: <BiCategoryAlt className="fs-5" />,
            //       label: "Category",
            //     },
            //     {
            //       key: "list-category",
            //       icon: <BiCategoryAlt className="fs-5" />,
            //       label: "Category List",
            //     },
            //     {
            //       key: "color",
            //       icon: <AiOutlineBgColors className="fs-5" />,
            //       label: "Color",
            //     },
            //     {
            //       key: "list-color",
            //       icon: <AiOutlineBgColors className="fs-5" />,
            //       label: "Color List",
            //     },
            //     {
            //       key: "Supplier",
            //       icon: <MdOutlineSupport className="fs-5" />,
            //       label: "Supplier",
            //     },
            //     {
            //       key: "SupplierList",
            //       icon: <MdOutlineSupport className="fs-5" />,
            //       label: "Supplier List",
            //     },
            //     {
            //       key: "Manufacturer",
            //       icon: <SiMakerbot className="fs-5" />,
            //       label: "Manufacturer",
            //     },
            //     {
            //       key: "ManufacturerList",
            //       icon: <SiMakerbot className="fs-5" />,
            //       label: "Manufacturer List",
            //     },
            //   ],
            // },
            // {
            //   key: "orders",
            //   icon: <FaClipboardList className="fs-5" />,
            //   label: "Orders",
            // },
            {
              key: "paymentList",
              icon: <MdPayment className="fs-5" />,
              label: "Payments",
            },
            {
              key: "appointments",
              icon: <GrSchedules className="fs-5" />,
              label: "Appointments",
            },
            // {
            //   key: "invoices",
            //   icon: <AiOutlineLayout className="fs-5" />,
            //   label: "Invoices",
            // },
            // {
            //   key: "reports",
            //   icon: <TbReportAnalytics className="fs-5" />,
            //   label: "Reports",
            //   children: [
            //     {
            //       key: "userManagementReports",
            //       icon: <AiOutlineUser className="fs-5" />,
            //       label: "User Reports",
            //     },
            //     {
            //       key: "appointmentReports",
            //       icon: <LuUsers className="fs-5" />,
            //       label: "Appointments",
            //     },
            //   ],
            // },
            {
              key: "signout",
              icon: <AiOutlineLogout className="fs-5" />,
              label: "Sign Out",
            },
          ]}
        />
      </Sider>
      <Layout>
        <Header
          className="d-flex justify-content-between ps-1 pe-5"
          style={{
            width: "100%",
            padding: 0,
            background: colorBgContainer,
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          }}
        >
          {React.createElement(collapsed ? MenuOutlined : MenuOutlined, {
            className: "trigger",
            onClick: () => setCollapsed(!collapsed),
          })}
          <div className="d-flex gap-4 align-items-center">
            <div className="d-flex gap-3 align-items-center dropdown">
              <div>
                <CgProfile size={30} />
              </div>
              <div
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <h5 className="mb-0">Admin</h5>
                <p className="mb-0">admin@eyemaster.com</p>
              </div>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <Link
                    className="dropdown-item py-1 mb-1"
                    style={{ height: "auto", lineHeight: "20px" }}
                    to="/"
                  >
                    View Profile
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item py-1 mb-1"
                    style={{ height: "auto", lineHeight: "20px" }}
                    to="/"
                    onClick={() => {
                      localStorage.clear();
                      window.location.reload();
                      navigate("/");
                    }}
                  >
                    Signout
                  </Link>
                </li>
              </div>
            </div>
          </div>
        </Header>
        <Content
          className=""
          // style={{
          //   padding: 80,
          //   margin: 0,
          //   minHeight: 280,
          //   background: colorBgContainer,
          // }}
        >
          <ToastContainer
            //   borderRadius: borderRadiusLG,
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            pauseOnFocusLoss
            rtl={false}
            draggable
            theme="light"
          />
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
