import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { deleteAColor, getColors } from "../features/color/colorSlice";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import CustomModal from "../components/CustomModal";
import Content from "../components/Content";
import { toast } from "react-toastify";

const columns = [
  {
    title: "SNo",
    dataIndex: "key",
  },
  {
    title: "title",
    dataIndex: "title",
  },
  {
    title: "Color",
    dataIndex: "Color",
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];

const Colorlist = () => {
  const [open, setOpen] = useState(false);
  const [colorId, setcolorId] = useState("");
  const showModal = (e) => {
    setOpen(true);
    setcolorId(e);
  };

  const hideModal = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getColors());
  }, [dispatch]);
  const colorState = useSelector((state) => state.color.colors);

  const data1 = [];
  for (let i = 0; i < colorState.length; i++) {
    data1.push({
      key: i + 1,
      title: colorState[i].title,
      Color: (
        <div className="col-3">
          <ul
            className="colors ps-0"
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              marginBottom: "10px",

              backgroundColor: colorState[i].colorName,
            }}
          ></ul>
        </div>
      ),
      action: (
        <>
          <Link
            to={`/admin/color/${colorState[i]._id}`}
            className="fs-3 text-success"
          >
            <BiEdit />
          </Link>
          <button
            className="ms-3 fs-3 text-danger bg-transparent border-0"
            onClick={() => showModal(colorState[i]._id)}
          >
            <AiFillDelete />
          </button>
        </>
      ),
    });
  }
  // const deleteColor = (e) => {
  //   dispatch(deleteAColor(e));

  //   setOpen(false);
  //   setTimeout(() => {
  //     dispatch(getColors());
  //   }, 100);
  // };

  const deleteColor = async (e) => {
    try {
      await dispatch(deleteAColor(e));
      toast.success("Color deleted Successfully");
      setOpen(false);
      dispatch(getColors());
    } catch (error) {
      console.error("Error deleting color:", error);
    }
  };

  return (
    <Content>
      <div>
        <h3 className="mb-4 title">Colors</h3>
        <div>
          <Table columns={columns} dataSource={data1} />
        </div>
        <CustomModal
          hideModal={hideModal}
          open={open}
          performAction={() => {
            deleteColor(colorId);
          }}
          title="Are you sure you want to delete this color?"
        />
      </div>
    </Content>
  );
};

export default Colorlist;
