import React from "react";

const Content = ({ page, children }) => {
  return (
    <div
      className="container-fluid mb-5 p-md-4 p-sm-3 p-3"
    >
      {!page ? children : null}
      {page === "component" ? (
        <div className="components-preview">{children}</div>
      ) : null}
    </div>
  );
};

export default Content;
