import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";

export const getCategories = async () => {
  const response = await axios.get(`${base_url}category`);
  return response.data;
};

export const createCategory = async (category) => {
  const response = await axios.post(`${base_url}category/`, category, config);
  return response.data;
};

export const getCategory = async (id) => {
  const response = await axios.get(`${base_url}category/${id}`, config);
  return response.data;
};

export const updateCategory = async (id, payload) => {
  const response = await axios.put(
    `${base_url}category/${id}`,
    payload,
    config
  );
  return response.data;
};

export const deleteCategory = async (id) => {
  const response = await axios.delete(`${base_url}category/${id}`, config);
  return response.data;
};
