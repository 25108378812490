import React from 'react'
import { useLocation } from 'react-router-dom'
import Content from '../components/Content';
import moment from 'moment';
import { Table } from "reactstrap";


const AppointmentView = () => {

    const location = useLocation();
    const data = location?.state?.appointment;
    console.log(data);

    const date = moment(data?.appointmentDateTime).utc().format("DD/MM/YYYY");
    const time = moment(data?.appointmentDateTime).utc().format("hh:mm A");


    const TableRow = ({ label, value, color }) => (
        <tr>
            <td className="text-nowrap lablesHeading" style={{ width: '50%' }}>{label}</td>
            <td className="text-nowrap" style={{ width: '50%', color: color }}>{value}</td>
        </tr>
    );



    const appointments = [
        {
            professional: "Dr. Smith",
            customer: "John Doe",
            date: "2024-10-07",
            time: "07:00 PM"
        },
        {
            professional: "Dr. Taylor",
            customer: "Jane Doe",
            date: "2024-10-08",
            time: "10:00 AM"
        },
        // Add more appointment objects here
    ];


    return (
        <Content>
            <div className='row'>
                <div className="mb-2 sub-title">Professional</div>
                <div className="col-md-3 form-group">
                    <label htmlFor="date" className='lablesHeading'>Name</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Select Date"
                        disabled
                        value={data?.Professional?.firstname ? data?.Professional?.firstname : "NA"}
                    />
                </div>
                <div className="col-md-3 form-group">
                    <label htmlFor="date" className='lablesHeading'>Email</label>
                    <input
                        type="text"
                        className="form-control"
                        disabled
                        value={data?.Professional?.email ? data?.Professional?.email : "NA"}

                    />
                </div>
                <div className="col-md-3 form-group">
                    <label htmlFor="date" className='lablesHeading'>Mobile</label>
                    <input
                        type="text"
                        className="form-control"
                        disabled
                        value={data?.Professional?.mobile ? data?.Professional?.mobile : "NA"}
                    />
                </div>
                <div className="col-md-3 form-group">
                    <label htmlFor="date" className='lablesHeading'>Address</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Select Date"
                        value={[data?.Professional?.city, data?.Professional?.state, data?.Professional?.country, data?.Professional?.pinCode].join(",")}
                        disabled
                    />
                </div>
                <div className="col-md-3 mt-3 form-group">
                    <label htmlFor="date" className='lablesHeading'>Qualification</label>
                    <input
                        type="text"
                        className="form-control"
                        value={data?.Professional?.qualification ? data?.Professional?.qualification : "NA"}
                        disabled
                    />
                </div>
                <div className="col-md-3 mt-3 form-group">
                    <label htmlFor="date" className='lablesHeading'>PerDay Eye Test</label>
                    <input
                        type="text"
                        className="form-control"
                        value={data?.Professional?.perDayEyeTest ? data?.Professional?.perDayEyeTest : "NA"}
                        disabled
                    />
                </div>
                <div className="col-md-3 mt-3 form-group">
                    <label htmlFor="date" className='lablesHeading'>Profession</label>
                    <input
                        type="text"
                        className="form-control"
                        value={data?.Professional?.profession ? data?.Professional?.profession : "NA"}
                        disabled
                    />
                </div>
                <div className="col-md-3 mt-3 form-group">
                    <label htmlFor="date" className='lablesHeading'>Speciality</label>
                    <input
                        type="text"
                        className="form-control"
                        value={data?.Professional?.speciality ? data?.Professional?.speciality : "NA"}
                        disabled
                    />
                </div>
            </div>
            <div className='row mt-4'>
                <div className="mb-2 sub-title">Customer</div>
                <div className="col-md-3 form-group">
                    <label htmlFor="date" className='lablesHeading'>Name</label>
                    <input
                        type="text"
                        className="form-control"
                        disabled
                        value={data?.user?.firstname}
                    />
                </div>
                <div className="col-md-3 form-group">
                    <label htmlFor="date" className='lablesHeading'>Email</label>
                    <input
                        type="text"
                        className="form-control"
                        value={data?.user?.firstname}
                        disabled
                    />
                </div>
                <div className="col-md-3 form-group">
                    <label htmlFor="date" className='lablesHeading'>Mobile</label>
                    <input
                        type="text"
                        className="form-control"
                        value={data?.user?.mobile}
                        disabled
                    />
                </div>
                <div className="col-md-3 form-group">
                    <label htmlFor="date" className='lablesHeading'>Address</label>
                    <input
                        type="text"
                        className="form-control"
                        disabled
                        value={[data?.user?.city, data.user.state, data?.user?.country].join(",")}
                    />
                </div>
            </div>
            <div className="mt-4 sub-title">Appointment Detail's</div>
            <div className="mt-3">
                <div className='row'>
                    <div className='col-md-12'>
                        <Table bordered hover className="fs-6" style={{ cursor: "pointer" }}>
                            <tbody>
                                <>
                                    <TableRow label="Appointment ID" value="NA" />
                                    <TableRow label="Date" value={date} />
                                    <TableRow label="Time" value={time} />
                                    <TableRow label="Symptom's" value={data?.diagnoses?.map((item) => item?.content)?.join(", ")} />
                                    <TableRow label="Location" value={data?.location} />
                                    <TableRow label="Status"
                                        color={data?.status === "completed" ? "green" : data?.status === "confirmed" ? "Blue" : "red"}
                                        value={data?.status?.charAt(0)?.toUpperCase() + data?.status?.slice(1)?.toLowerCase()} />
                                </>
                            </tbody>
                        </Table>
                    </div>
                </div>

            </div>

            <div className="mt-4 sub-title">Appointment History</div>
            <table className="table table-striped mt-3">
                <thead>
                    <tr>
                        <th>Professional</th>
                        <th>Customer</th>
                        <th>Date</th>
                        <th>Time</th>
                    </tr>
                </thead>
                <tbody>
                    {appointments?.map((appointment, index) => (
                        <tr key={index}>
                            <td>{appointment.professional}</td>
                            <td>{appointment.customer}</td>
                            <td>{appointment.date}</td>
                            <td>{appointment.time}</td>
                        </tr>
                    ))}
                </tbody>
            </table>


        </Content>
    )
}

export default AppointmentView