import { React, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useLocation, useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import { Formik, Field, Form, FieldArray, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getBrands } from "../features/brand/brandSlice";
import { getCategories } from "../features/pcategory/pcategorySlice";
import { getColors } from "../features/color/colorSlice";
import { Select } from "antd";
import Dropzone from "react-dropzone";
import { delImg, uploadImg } from "../features/upload/uploadSlice";
import Loader from "../components/Loader/Loader";
import {
  createProducts,
  getAProduct,
  resetState,
  updateAProduct,
} from "../features/product/productSlice";
import Content from "../components/Content";
import CustomInput from "./../components/CustomInput";
import { getManufacturers } from "../features/Manufacturer/ManufacturerService";
import Manufacturer from "./Manufacturer";
import Supplier from "./Supplier";
import { getSuppliers } from "../features/supplier/supplierService";

import { Tabs } from "antd";

export const AddNewProduct = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const getProductId = location.pathname.split("/")[3];
  const navigate = useNavigate();
  const [color, setColor] = useState([]);
  const [images, setImages] = useState([]);
  const [manfData, setManfData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    dispatch(getBrands());
    dispatch(getCategories());
    dispatch(getColors());
  }, []);

  const brandState = useSelector((state) => state.brand.brands);
  const catState = useSelector((state) => state.pCategory.pCategories);
  const colorState = useSelector((state) => state.color.colors);
  const imgState = useSelector((state) => state?.upload?.images);
  const newProduct = useSelector((state) => state.product);

  const {
    isSuccess,
    isError,
    isLoading,
    createdProduct,
    updatedProduct,
    productName,
    productDesc,
    productPrice,
    productBrand,
    productCategory,
    productManufacturer,
    productSupplier,
    productTag,
    productColors,
    productQuantity,
    productImages,
    productMaterialType,
    productMaterialName,
    productWaterContent,
    productOxygenTransmissibility,
    productBaseCurve,
    productPowerSpherical,
    productPowerCyl,
    productPowerAxis,
    productPowerAdd,
    productPackSize,
    productSubTitle,
    productUsageModal,
  } = newProduct;

  const validationSchema = yup.object().shape({
    people: yup.array().of(
      yup.object().shape({
        firstName: yup.string().max(10).required(),
        lastName: yup.string().max(10),
      })
    ),
    dogs: yup.array().of(
      yup.object().shape({
        name: yup.string().required("Required"),
      })
    ),
  });

  let initialValue = {
    title: productName || "",
    description: productDesc || "",
    brand: productBrand || "",
    category: productCategory || "",
    manufacturer: productManufacturer || "",
    supplier: productSupplier || "",
    color: productColors || "",
    materialType: productMaterialType || "",
    materialName: productMaterialName || "",
    waterContent: productWaterContent || "",
    oxygenTransmissibility: productOxygenTransmissibility || "",
    baseCurve: productBaseCurve || "",
    powerSpherical: productPowerSpherical || "",
    powerCyl: productPowerCyl || "",
    powerAxis: productPowerAxis || "",
    powerAdd: productPowerAdd || "",
    packSize: productPackSize || "",
    subTitle: productSubTitle || "",
    usageModal: productUsageModal || "",
    quantity: productQuantity || "",
    images: productImages || "",

    countryDetails: [
      {
        country: "",
        basePrice: 0,
        sellingPrice: 0,
        currency: "",
        stock: 0,
      },
      {
        country: "",
        basePrice: 0,
        sellingPrice: 0,
        currency: "",
        stock: 0,
      },
    ],
  };

  useEffect(() => {
    if (getProductId !== undefined) {
      dispatch(getAProduct(getProductId));
    } else {
      dispatch(resetState());
    }
  }, [getProductId]);

  useEffect(() => {
    if (isSuccess && createdProduct) {
      toast.success("Product Added Successfullly!");
      navigate("/admin/list-product");
    }
    if (isSuccess && updatedProduct) {
      toast.success("Product Updated Successfullly!");
      navigate("/admin/list-product");
    }
    if (isError) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, isError, isLoading, updatedProduct]);

  const coloropt = [];
  colorState.forEach((i) => {
    coloropt.push({
      label: (
        <div className="d-flex align-items-center">
          <div
            style={{
              width: "20px",
              height: "20px",
              // marginBottom: "10px",
              backgroundColor: i.title,
              borderRadius: "50%",
              listStyle: "none",
              border: "2px solid transparent",
              marginRight: "10px", // Add spacing between color and name
            }}
          ></div>
          <span>{i.title}</span> {/* Display color name */}
        </div>
      ),
      value: i._id,
    });
  });

  const productcolor = [];
  productColors?.forEach((i) => {
    productcolor.push({
      label: (
        <div className="col-3">
          <ul
            className="colors ps-0"
            style={{
              width: "20px",
              height: "20px",
              marginBottom: "10px",
              backgroundColor: i.title,
              borderRadius: "50%", // Added inline style for rounded shape
              listStyle: "none", // Hide bullet points
              border: "2px solid transparent",
            }}
          ></ul>
        </div>
      ),
      value: i._id,
    });
  });

  let img = [];
  imgState?.data?.forEach((i) => {
    img?.push({
      fileName: i?.fileName,
      fileUrl: i?.fileUrl,
    });
  });

  let [imgshow, setImgShow] = useState([]);
  useEffect(() => {
    if (location.pathname.includes("/admin/product")) {
      setImgShow([]);
    }
  }, [location.pathname]);

  useEffect(() => {
    console.log("at the time of add--");
    if (productImages?.length !== 0) {
      productImages?.forEach((i) => {
        imgshow.push({
          fileName: i?.fileName,
          fileUrl: i?.fileUrl,
        });
      });
    }
  }, [productImages]);

  useEffect(() => {
    initialValue.color = color ? color : " ";
    initialValue.images = img;
  }, [color, img]);

  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await getManufacturers();
      setManfData(response);
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData2 = async () => {
    setLoader(true);
    try {
      const response = await getSuppliers();
      setSupplierData(response);
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };
  useEffect(() => {
    fetchData2();
  }, []);

  const handleColors = (e) => {
    setColor(e);
  };

  const handleDeleteImage = (fileName) => {
    dispatch(delImg(fileName)).then(() => {
      const updatedImages = imgshow.filter((img) => img?.fileName !== fileName);
      imgshow.length = 0;
      setImgShow(updatedImages);
      initialValue.setFieldValue("images", [...updatedImages]);
    });
  };

  function formSubmit(v) {
    console.log(v, "ppp");
  }
  let country1 = ["India", "Uae"];

  return (
    <div>
      <Formik
        initialValues={initialValue}
        onSubmit={formSubmit}
        validationSchema={validationSchema}
      >
        {({ handleChange, handleBlur, values, errors, touched }) => (
          <Form>
            <div className="row mb-3">
              <div className="col-md-6 col-sm-6 col-12">
                <CustomInput
                  type="text"
                  label="Enter product title"
                  name="title"
                  onChng={handleChange}
                  onBlr={handleBlur}
                  val={values.title}
                />
                {/* <div className="error mt-2 fs-6">
                  {formik.touched.title && formik.errors.title}
                </div> */}
              </div>
              <div className="col-md-6 col-sm-6 col-12">
                <CustomInput
                  type="text"
                  label="Enter sub-title"
                  name="subTitle"
                  onChng={handleChange}
                  onBlr={handleBlur}
                  val={values.subTitle}
                />
                {/* <div className="error mt-2 fs-6">
                  {formik.touched.subTitle && formik.errors.subTitle}
                </div> */}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-6 col-sm-6 col-12">
                <select
                  name="brand"
                  loading
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.brand}
                  className="form-select py-3 mb-3"
                  id=""
                >
                  <option value="">Select Brand</option>
                  {brandState.map((i, j) => {
                    return (
                      <option key={j} value={i._id}>
                        {i.title}
                      </option>
                    );
                  })}
                </select>
                {/* <div className="error mt-2 fs-6">
                  {formik.touched.brand && formik.errors.brand}
                </div> */}
              </div>
              <div className="col-md-6 col-sm-6 col-12">
                <select
                  name="category"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.category}
                  className="form-select py-3 mb-3"
                  id=""
                >
                  <option value="">Select Category</option>
                  {catState.map((i, j) => {
                    return (
                      <option key={j} value={i._id}>
                        {i.title}
                      </option>
                    );
                  })}
                </select>
                {/* <div className="error mt-2 fs-6">
                  {formik.touched.category && formik.errors.category}
                </div> */}
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-6 col-sm-6 col-12">
                <select
                  name="manufacturer"
                  className="form-select py-3 mb-3"
                  id=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.manufacturer}
                >
                  <option value="">Select Manufacturer</option>
                  {manfData?.map((item, index) => {
                    return (
                      <option key={index} value={item?._id}>
                        {item?.manufacturerName}
                      </option>
                    );
                  })}
                </select>
                {/* <div className="error mt-2 fs-6">
                  {formik.touched.manufacturer && formik.errors.manufacturer}
                </div> */}
              </div>
              <div className="col-md-6 col-sm-6 col-12">
                <select
                  name="supplier"
                  className="form-select py-3 mb-3"
                  id=""
                  onChange={handleChange}
                  onBlur={handleChange}
                  value={values.supplier}
                >
                  <option value="">Select Supplier</option>
                  {supplierData?.map((item, index) => {
                    return (
                      <option key={index} value={item?._id}>
                        {item?.supplierName}
                      </option>
                    );
                  })}
                </select>
                {/* <div className="error mt-2 fs-6">
                  {formik.touched.supplier && formik.errors.supplier}
                </div> */}
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-6 col-sm-6 col-12 mt-3 ">
                <Select
                  mode="multiple"
                  allowClear
                  className="form-multiselect w-100"
                  placeholder="Select colors"
                  value={color}
                  // defaultValue={productcolor || color}
                  onChange={(i) => {
                    handleColors(i);
                  }}
                  options={coloropt}
                />
                {/* <div className="error mt-2 fs-6">
                  {formik.touched.color && formik.errors.color}
                </div> */}
              </div>
              <div className="col-md-6 col-sm-6 col-12">
                <CustomInput
                  type="number"
                  label="Enter product quantity"
                  name="quantity"
                  onChng={handleChange}
                  onBlr={handleBlur}
                  val={values.quantity}
                />
                {/* <div className="error mt-2 fs-6">
                  {formik.touched.quantity && formik.errors.quantity}
                </div> */}
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-6 col-sm-6 col-12">
                <CustomInput
                  type="text"
                  label="Enter Material Name"
                  name="materialName"
                  onChng={handleChange}
                  onBlr={handleBlur}
                  val={values.materialName}
                />
                {/* <div className="error mt-2 fs-6">
                  {formik.touched.materialName && formik.errors.materialName}
                </div> */}
              </div>

              <div className="col-md-6 col-sm-6 col-12">
                <CustomInput
                  type="text"
                  label="Enter material type"
                  name="materialType"
                  onChng={handleChange}
                  onBlr={handleBlur}
                  val={values.materialType}
                />
                {/* <div className="error mt-2 fs-6">
                  {formik.touched.materialType && formik.errors.materialType}
                </div> */}
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-6 col-sm-6 col-12">
                <CustomInput
                  type="number"
                  label="Enter water content"
                  name="waterContent"
                  onChng={handleChange}
                  onBlr={handleBlur}
                  val={values.waterContent}
                />
                {/* <div className="error mt-2 fs-6">
                  {formik.touched.waterContent && formik.errors.waterContent}
                </div> */}
              </div>

              <div className="col-md-6 col-sm-6 col-12">
                <CustomInput
                  type="text"
                  label="Enter oxygen transmissibility"
                  name="oxygenTransmissibility"
                  onChng={handleChange}
                  onBlr={handleBlur}
                  val={values.oxygenTransmissibility}
                />
                {/* <div className="error mt-2 fs-6">
                  {formik.touched.oxygenTransmissibility &&
                    formik.errors.oxygenTransmissibility}
                </div> */}
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-6 col-sm-6 col-12">
                <CustomInput
                  type="text"
                  label="Enter base curve"
                  name="baseCurve"
                  onChng={handleChange}
                  onBlr={handleBlur}
                  val={values.baseCurve}
                />
                {/* <div className="error mt-2 fs-6">
                  {formik.touched.baseCurve && formik.errors.baseCurve}
                </div> */}
              </div>

              <div className="col-md-6 col-sm-6 col-12">
                <CustomInput
                  type="text"
                  label="Enter power spherical"
                  name="powerSpherical"
                  onChng={handleChange}
                  onBlr={handleBlur}
                  val={values.powerSpherical}
                />
                {/* <div className="error mt-2 fs-6">
                  {formik.touched.powerSpherical &&
                    formik.errors.powerSpherical}
                </div> */}
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-6 col-sm-6 col-12">
                <CustomInput
                  type="text"
                  label="Enter power cyl"
                  name="powerCyl"
                  onChng={handleChange}
                  onBlr={handleBlur}
                  val={values.powerCyl}
                />
                {/* <div className="error mt-2 fs-6">
                  {formik.touched.powerCyl && formik.errors.powerCyl}
                </div> */}
              </div>

              <div className="col-md-6 col-sm-6 col-12">
                <CustomInput
                  type="text"
                  label="Enter power axis"
                  name="powerAxis"
                  onChng={handleChange}
                  onBlr={handleBlur}
                  val={values.powerAxis}
                />
                {/* <div className="error mt-2 fs-6">
                  {formik.touched.powerAxis && formik.errors.powerAxis}
                </div> */}
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-6 col-sm-6 col-12">
                <CustomInput
                  type="text"
                  label="Enter power add"
                  name="powerAdd"
                  onChng={handleChange}
                  onBlr={handleBlur}
                  val={values.powerAdd}
                />
                {/* <div className="error mt-2 fs-6">
                  {formik.touched.powerAdd && formik.errors.powerAdd}
                </div> */}
              </div>

              <div className="col-md-6 col-sm-6 col-12">
                <CustomInput
                  type="text"
                  label="Enter pack size"
                  name="packSize"
                  onChng={handleChange}
                  onBlr={handleBlur}
                  val={values.packSize}
                />
                {/* <div className="error mt-2 fs-6">
                  {formik.touched.packSize && formik.errors.packSize}
                </div> */}
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-6 col-sm-6 col-12">
                <select
                  name="useageModad"
                  className="form-select py-3 mt-3"
                  id=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.usageModal}
                >
                  <option value="">Select Usage Modal</option>

                  <option value={"dailies"}>Dailies</option>
                  <option value={"monthly"}>Monthly</option>
                </select>
                {/* <div className="error mt-2 fs-6">
                  {formik.touched.usageModal && formik.errors.usageModal}
                </div> */}
              </div>
            </div>

            <FieldArray name="countryDetails">
              {({ push, remove }) => (
                <div className="d-flex flex-column gap-4">
                  {values.countryDetails.map((country, index) => {
                    return (
                      <>
                        <div className="row mt-2">
                          <div className="labelHeading col-12">
                            {country.country}
                          </div>

                          <div className="col-md-6 col-sm-6 col-12">
                            <select
                              name={`countryDetails[${index}].country`}
                              className="form-select py-3 mt-3"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={country.country}
                            >
                              <option value="">Select Country</option>

                              <option value={"UAE"}>UAE</option>
                              <option value={"INDIA"}>India</option>
                            </select>
                            {/* <div className="error mt-2 fs-6">
                              {formik.touched.basePriceUAE &&
                                formik.errors.basePriceUAE}
                            </div> */}
                          </div>

                          <div className="col-md-6 col-sm-6 col-12">
                            <CustomInput
                              type="number"
                              label="Base Price"
                              name={`countryDetails[${index}].basePrice`}
                              onChng={handleChange}
                              onBlr={handleBlur}
                              val={country.basePrice}
                            />
                            {/* <div className="error mt-2 fs-6">
                  {formik.touched.packSize && formik.errors.packSize}
                </div> */}
                          </div>

                          <div className="col-md-6 col-sm-6 col-12">
                            <CustomInput
                              type="number"
                              label="Selling Price"
                              name={`countryDetails[${index}].sellingPrice`}
                              onChng={handleChange}
                              onBlr={handleBlur}
                              val={country.sellingPrice}
                            />
                            {/* <div className="error mt-2 fs-6">
                  {formik.touched.packSize && formik.errors.packSize}
                </div> */}
                          </div>

                          <div className="col-md-6 col-sm-6 col-12">
                            <CustomInput
                              type="number"
                              label="Stock UAE"
                              name={`countryDetails[${index}].stock`}
                              onChng={handleChange}
                              onBlr={handleBlur}
                              val={country.stock}
                            />
                            {/* <div className="error mt-2 fs-6">
                  {formik.touched.packSize && formik.errors.packSize}
                </div> */}
                          </div>

                          <div className="col-md-6 col-sm-6 col-12">
                            <select
                              name={`countryDetails[${index}].country`}
                              className="form-select py-3 mt-3"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={country.country}
                            >
                              <option value="">Select Currency</option>

                              <option value={"AED"}>Aed</option>
                              <option value={"INR"}>Inr</option>
                            </select>
                            {/* <div className="error mt-2 fs-6">
                              {formik.touched.basePriceUAE &&
                                formik.errors.basePriceUAE}
                            </div> */}
                          </div>

                          {/* <div className="col-md-4">
                            <CustomInput
                              type="number"
                              label="Selling Price (UAE)"
                              name={`countryDetails[${index}].basePrice`}
                              onChng={handleChange}
                              onBlr={handleBlur}
                              val={country.basePrice}
                            />
                           
                          </div> */}
                        </div>
                      </>
                    );
                  })}
                </div>
              )}
            </FieldArray>

            <div className="labelHeading">Description</div>
            <div className="">
              <ReactQuill
                style={{ height: "10rem " }}
                theme="snow"
                name="description"
                onChange={handleChange}
                value={values.description}
              />
            </div>
            {/* <div className="error mt-2 fs-6">
              {formik.touched.description && formik.errors.description}
            </div> */}

            <div className="labelHeading mt-5">Images</div>
            <div className="bg-white border-1 p-5 text-center border">
              <Dropzone
                onDrop={(acceptedFiles) => dispatch(uploadImg(acceptedFiles))}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()} style={{ cursor: "pointer" }}>
                      <input {...getInputProps()} />
                      <p>Click to Select Files</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>

            {console.log(imgshow, "uploaded image-----------")}

            <div className="showimages d-flex flex-wrap gap-3">
              {imgshow?.map((i, j) => {
                return (
                  <div className=" position-relative" key={j}>
                    <button
                      type="button"
                      onClick={() => handleDeleteImage(i.fileName)}
                      className="btn-close position-absolute"
                      style={{ top: "10px", right: "10px" }}
                    ></button>
                    <img src={i.fileUrl} alt="" width={200} height={200} />
                  </div>
                );
              })}
              {imgState?.data?.map((i, j) => {
                return (
                  <div className=" position-relative" key={j}>
                    <button
                      type="button"
                      onClick={() => handleDeleteImage(i.fileName)}
                      className="btn-close position-absolute"
                      style={{ top: "10px", right: "10px" }}
                    ></button>
                    <img src={i.fileUrl} alt="" width={200} height={200} />
                  </div>
                );
              })}
            </div>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary  border-0 rounded-3 my-3"
                type="submit"
              >
                {getProductId !== undefined ? "Update" : "Add"} Product
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
