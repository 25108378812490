import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Content from "../components/Content";
import { updateProfessional } from "../features/professionals/ProfessionalService";
import { DeleteDocuments, uploadDocuments } from "../features/customers/CustomerService";
import Loader from "../components/Loader/Loader";
import { IoMdClose } from "react-icons/io";
import { Modal, ModalBody } from 'reactstrap';
import { FaRegCheckCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import { IoCloseSharp } from "react-icons/io5";
import { MdOutlineFileUpload } from "react-icons/md";
import Multiselect from "multiselect-react-dropdown";
import MapComponent from "../components/MapComponents/MapComponent";
import SearchBox from "../components/MapComponents/SearchBox";
import { IoArrowBack } from "react-icons/io5";
import { statusChange, updateProfessionalData, viewProfessional } from "../services/apis";
import { RxAvatar } from "react-icons/rx";
import { FaEdit } from "react-icons/fa";


const ProfessionalEdit = () => {
    const navigate = useNavigate();
    let { id } = useParams()
    const [loading, setLoading] = useState(false);
    const [profileImage, setProfileImage] = useState(null);
    const [img, setImg] = useState("");
    const [placeholder, setPlaceholder] = useState("Select");
    const [speciality, setSpeciality] = useState([])
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false)
    const [doc, setDoc] = useState([])
    const [innerData, setinnerData] = useState({})
    const [selectedPosition, setSelectedPosition] = useState({
        lat: 40.7128,
        lng: -74.0060,
    });
    const [docForDelete, setDocForDelete] = useState([])
    const apiKey = 'AIzaSyCloFlxsLYW2fYowdLLwu7K7XaJz0mMrxQ'
    const [addressData, setAddressData] = useState({
        city: '',
        state: '',
        country: '',
        address: ''
    })
    const specialityOption = ['Refraction', 'Paediatric', 'Medicines', 'Surgery', 'Orthoptics', 'Artificial Eye', 'LASIK', 'Contact Lenses']
    const [formData, setFormData] = useState({
        firstname: "",
        prefix: "",
        pinCode: "",
        yearsOfExperience: "",
        organization: "",
        qualification: "",
        profession: "",
        speciality: "",
        company: "",
        perDayEyeTest: '',
        slitLampAvailable: ''
    })

    const [statusValue, setStatusValue] = useState('')
    const [reasonForSuspend, setReasonForSuspend] = useState('')
    const [statusModal, setStatusModal] = useState(false)

    function statuscolor(key) {
        switch (key) {
          case 'approved':
            return 'text-success'
            break;
          case 'under review':
            return 'text-warning'
            break;
          case 'disapproved':
            return 'text-danger'
            break;
    
          default:
            break;
        }
      }


      async function changeStatus2(e) {
        e.preventDefault()
        if (statusValue == 'under review') {
          toast.error("You can't update status as Under Review")
          return
        }
        setLoading(true)
        let payload = {
          "status": statusValue,
          "reason": reasonForSuspend
        }
        try {
          await statusChange(formData?._id, payload)
          await getDataById()
          toggleModal()
          toast.success('Status update successfully')
        } catch (err) {
          console.log(err)
          if (
            err?.response?.data?.message ==
            "Not Authorized token expired,Please Login again"
          ) {
            localStorage.clear();
            toast.error("session expired please login again.");
            navigate("/");
          }else{

              toast.error('There are some error occupide please login again then continue')
          }
        } finally {
          setLoading(false)
        }
      }
    
      function toggleModal() {
        setStatusModal(false)
        setReasonForSuspend('')
        setStatusValue('')
      }
    


    async function getDataById() {
        setLoading(true)
        try {
            let res = await viewProfessional(id)
            let data = res?.data?.user || {}
            setFormData(data)
            setSpeciality(data?.speciality)
            setProfileImage(data?.profile?.fileUrl)
            let dd = data?.degree_document?.map(res => {
                return {
                    ...res,
                    file: null
                }
            })
            setDoc(dd)
            setAddressData(pre => {
                return {
                    ...pre,
                    address: data?.address,
                    state: data?.state,
                    city: data?.city,
                    country: data?.country
                }
            })
            let pos = {
                lat: +(data?.lat_long?.lat) || null,
                lng: +(data?.lat_long?.long) || null
            }
            setSelectedPosition(pos)
            if (data?.speciality?.length !== 0) {
                setPlaceholder('')
            }
            //   setStatusValue(data?.profile_status)
            console.log(data)

        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getDataById()
    }, [])


    function changeStatusUi(status) {
        switch (status) {
            case 'disapproved':
                return <div className="statusOuterin">
                    <div className="statusDotin" />
                    <div className="Statusin">Disapproved</div>
                </div>
                break;
            case 'under review':
                return <div className="statusOuterr">
                    <div className="statusDotr" />
                    <div className="Statusr">Under Review</div>
                </div>
                break;
            case 'approved':
                return <div className="statusOuterA">
                    <div className="statusDotA" />
                    <div className="StatusA">Approved</div>
                </div>
                break;

            default:
                break;
        }
    }




    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const handleFileInput = (e) => {
        const file = e.target.files[0];

        if (file) {
            setImg(file);
            const imageUrl = URL.createObjectURL(file);
            setProfileImage(imageUrl);
        }
    };

    const handleImage = async () => {
        if (img?.name !== undefined) {
            const deletePayload = {
                data: [formData.profile?.fileName, ...docForDelete]
            };
            console.log(deletePayload)

            const formData1 = new FormData();
            formData1.append('upload', img);
            try {
                if (formData.profile?.fileName !== undefined) {
                    let deleteDoc = await DeleteDocuments(deletePayload)
                    console.log(deleteDoc)
                }
                const response = await uploadDocuments(formData1);
                console.log(response)
                return response?.data[0] || {}
            } catch (error) {
                console.log(error);
            }
        } else {
            if (docForDelete?.length !== 0) {

                const deletePayload = {
                    data: [...docForDelete]
                };
                console.log(deletePayload)
                let deleteDoc = await DeleteDocuments(deletePayload)
                console.log(deleteDoc)
            }
            return formData?.profile
        }
    }


    const handleDocUpload = async () => {
        let arr = [...doc]
        console.log(arr)
        let fileForUpload = []
        let fileForNotUpload = []
        arr?.forEach(res => {
            if (res?.file?.name !== undefined) {
                fileForUpload.push(res?.file)
            } else {
                fileForNotUpload.push({
                    fileName: res?.fileName,
                    fileUrl: res?.fileUrl
                })
            }

        })
        console.log(fileForUpload)
        console.log(fileForNotUpload)
        if (fileForUpload?.length == 0) {
            return fileForNotUpload
        } else {
            const formData = new FormData();
            fileForUpload?.forEach(res => {
                formData.append('upload', res);
            })
            try {
                const response = await uploadDocuments(formData);
                console.log(response)
                return [...fileForNotUpload, ...response?.data] || []
            } catch (error) {
                console.log(error);
            }
        }
    }


    const handleProfile = async (e) => {
        e.preventDefault();
        if (speciality?.length > 4) {
            toast.error("You can only select max 4 specialities")
            return
        }
        if (speciality?.length == 0) {
            toast.error("Please select specialities")
            return
        }
        if (addressData.address == '') {
            toast.error("Please select address")
            return
        }
        // if (!img) {
        //     toast.error("Select profile picture")
        //     return;
        // }
        setLoading(true)
        const imageURl = await handleImage();
        const documents = await handleDocUpload();
        const payload = {
            ...formData,
            profile: imageURl,
            degree_document: documents,
            date_of_birth: "",
            ...addressData,
            speciality: speciality || [],
            lat_long: {
                lat: selectedPosition?.lat,
                long: selectedPosition?.lng
            },
            userId: formData?._id,
        }
        delete payload?._id
        console.log(payload)
        // return
        try {
            const response = await updateProfessionalData(payload);
            toast.success("Professional Profile updated successfully!")
            navigate("/admin/professionals");
            setLoading(false)
            setOpenSuccessDialog(true)
        } catch (error) {
            console.log(error);
            setLoading(false);
            if (
              error?.response?.data?.message ==
              "Not Authorized token expired,Please Login again"
            ) {
              localStorage.clear();
              toast.error("session expired please login again.");
              navigate("/");
            }
            const errorMessage = error?.response?.data?.message || 'There are some error occured.'
            toast.error(errorMessage)
        }
    }


    function back() {
        navigate("/");
    }

    const onSelect = (selectedList) => {
        setSpeciality(selectedList);
        setPlaceholder("");
    };

    const onRemove = (selectedList) => {
        setSpeciality(selectedList);
        if (selectedList.length === 0) {
            setPlaceholder("Select");
        }
    };

    useEffect(() => {
        const handleGetAddress = async () => {
            setLoading(true);
            try {
                const addr = await getAddressFromLatLng(selectedPosition.lat, selectedPosition.lng, apiKey);
                if (addr && innerData?.formatted_address !== undefined) {
                    const addressComponents = innerData?.address_components;
                    const country = addressComponents.find((comp) => comp.types.includes('country'))?.long_name;
                    const state = addressComponents.find((comp) => comp.types.includes('administrative_area_level_1'))?.long_name;
                    const city = addressComponents.find(
                        (comp) =>
                            comp.types.includes('locality') ||
                            comp.types.includes('administrative_area_level_2')
                    )?.long_name;
                    setAddressData({
                        address: addr,
                        country,
                        state,
                        city,
                    });
                }
            } catch (err) {
                console.error('Error fetching address: ', err);
            } finally {
                setLoading(false);
            }
        };
        handleGetAddress();

    }, [innerData])

    const getAddressFromLatLng = async (lat, lng, apiKey) => {
        const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

        try {
            const response = await fetch(geocodeUrl);
            const data = await response.json();

            if (data.status === 'OK') {
                // Return the formatted address (the first result)
                return data.results[0].formatted_address;
            } else {
                console.error('Geocode failed due to: ', data.status);
                return null;
            }
        } catch (error) {
            console.error('Error with geocoding API: ', error);
            return null;
        }
    };


    function removeDoc(index) {
        let arr = [...doc]
        let [removed] = arr?.splice(index, 1)
        if (removed?.file == null) {
            setDocForDelete(pre => {
                return [...pre, removed?.fileName]
            })
        }
        setDoc(arr)

    }



    return (
        <Content>
            <Loader open={loading} />
            <div className='container'>
                <Modal isOpen={statusModal} centered >
                    <ModalBody>
                        <form className="mt-3" onSubmit={changeStatus2}>
                            <label className="form-label fw-bold">Status</label>
                            <select
                                className={`form-select bg-transparent f-14 px-1 ${statuscolor(statusValue)}`}
                                value={statusValue}
                                onChange={(e) => setStatusValue(e.target.value)}
                                style={{ minWidth: '130px' }}
                            >
                                <option className='f-14' disabled selected value=''>Select Status</option>
                                <option className='f-14 text-success' value='approved'>Approved</option>
                                <option className='f-14 text-warning' disabled value='under review'>Under Review</option>
                                <option className='f-14 text-danger' value='disapproved'>Disapproved</option>
                            </select>
                            {statusValue == 'disapproved' ? <>
                                <label className="form-label fw-bold mt-3">Enter Reason</label>
                                <textarea className="form-control" required rows={5} placeholder="Enter your reason here" value={reasonForSuspend} onChange={(e) => {
                                    if (e.target.value == ' ') {
                                        e.target.value = ''
                                    } else {
                                        setReasonForSuspend(e.target.value)
                                    }
                                }}></textarea>
                            </> : ''}
                            <div className="d-flex justify-content-end gap-3 mt-3">
                                <button className='btn btn-sm border' type="button" onClick={toggleModal}>Cancel</button>
                                <button className="btn btn-sm btn-primary" type="submit">Submit</button>
                            </div>
                        </form>
                    </ModalBody>
                </Modal>
                <Modal centered isOpen={openSuccessDialog} size='lg'>
                    <ModalBody>
                        <div className=''>
                            <div className='d-flex justify-content-end'>
                                <IoCloseSharp className='pointer closeIcon' onClick={back} />
                            </div>
                            <div className='d-flex justify-content-center'><FaRegCheckCircle className='thankuIcon' /></div>
                            <div className='thankuText mt-3'> profile is saved for review</div>
                            <div className='thankuText2'>We will get back to you soon</div>
                            <div className='d-flex justify-content-center mt-2'><button className='btn btn-primary' onClick={back}>Close</button></div>
                        </div>
                    </ModalBody>
                </Modal>
                <button className='btn ps-0 d-flex align-items-center' onClick={() => navigate('/admin/professionals')}><IoArrowBack className='me-1' /> Back</button>
                <form onSubmit={handleProfile}>
                    <div className="d-flex justify-content-between mt-3">
                        <div className='px-2 fs-4 fw-bold'>Edit Professional Profile</div>
                        <div className='pointer' onClick={()=>setStatusModal(true)}>
                        {changeStatusUi(formData?.profile_status)}
                        {/* <FaEdit className="fs-5 pointer"  /> */}
                        </div>
                    </div>
                    {/* <div className='text-gray px-3'>Fill in the profile data. It will take a couple of minutes. </div> */}
                    <div className='row mt-4'>
                        <div className='col-12 px-3' >
                            <div className='d-flex align-items-center flex-md-row flex-sm-row flex-column'>
                                <div className='profilePreview'>
                                    {profileImage ?
                                        <img
                                            src={profileImage}
                                            alt="Profile Preview"
                                            style={{ width: 'auto', height: '100%' }}
                                        />
                                        : 
                                        <img
                                            src='/images/profile.png'
                                            alt="Profile Preview"
                                            style={{ width: 'auto', height: '100%' }}
                                        />
                                        
                                        }
                                </div>
                                <div className='ms-4 d-flex flex-column'>
                                    <label className='labelHeading mb-1'>
                                        Update Profile Picture<span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        // required
                                        className="form-control-file pointer"
                                        name='profile'
                                        onChange={handleFileInput}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='w-100 mt-4'>
                        <label className='labelHeading mb-1'>Upload Degree/Diploma document</label>
                        <input type='file' className='form-control' accept=".pdf, image/*" hidden placeholder='Upload' id='uploadDoc' multiple name='pinCode' onChange={(e) => {
                            let dd = Object.values(e.target.files)
                            let dd2 = dd?.map(res => {
                                return {
                                    file: res,
                                    fileName: res?.name,
                                    fileurl: ''
                                }
                            })
                            setDoc(pre => {
                                return [...pre, ...dd2]
                            })
                        }} />
                        <div className='w-100 border rounded bg-white' >
                            {doc?.length == 0 ? <>
                                <label className='uploadtext pointer py-3' htmlFor='uploadDoc'>
                                    <div className='d-flex justify-content-center'>
                                        <MdOutlineFileUpload className='uploadIcon' />
                                    </div>
                                    <div className='text-center'>Click to upload document</div>
                                </label>
                            </> : <>
                                <div className='fileContainer'>
                                    {doc?.map((res, index) => {
                                        return <div className='fileStyle border rounded p-1' key={index}>
                                            <div className='d-flex justify-content-end w-100'>
                                                <IoMdClose className='pointer fs-4' onClick={() => removeDoc(index)} />
                                            </div>
                                            <div className='text-center mt-2' style={{ wordWrap: 'break-word', overflowWrap: 'break-word' }} title={res?.fileName}>{res?.fileName?.length > 15 ? `${res?.fileName?.slice(0, 15)}...` : res?.fileName}</div>
                                        </div>
                                    })}
                                    <div className='fileStyle border rounded p-1'>
                                        <label className='uploadtext w-100 pointer py-3' htmlFor='uploadDoc'>
                                            <div className='d-flex justify-content-center'>
                                                <MdOutlineFileUpload className='uploadIcon' />
                                            </div>
                                            {/* <div className='text-center'>Click to select document</div> */}
                                        </label>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <div className='col-md-4 col-12 mt-3'>
                            <label className='labelHeading mb-1'>Prefix<span className='text-danger'>*</span></label>
                            <select
                                name="prefix"
                                className="form-control pointer"
                                placeholder="Select Prefix"
                                value={formData.prefix}
                                onChange={handleChange}
                            >
                                <option value="" disabled selected>
                                    Select Prefix
                                </option>
                                <option value="Mr." className='pointer'>Mr.</option>
                                <option value="Ms." className='pointer'>Ms.</option>
                                <option value="Dr." className='pointer'>Dr.</option>
                            </select>
                        </div>
                        <div className='col-md-4 col-sm-8 col-12 mt-3'>
                            <label className='labelHeading mb-1'>Full Name<span className='text-danger'>*</span></label>
                            <input
                                placeholder='Enter Full Name'
                                required
                                className="form-control"
                                name='firstname'
                                type="text"
                                value={formData.firstname}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='col-md-4 col-12 mt-3'>

                            <label className='labelHeading mb-1'>Eye Care Organization<span className='text-danger'>*</span></label>
                            <input
                                placeholder='Enter Company Name'
                                required
                                className="form-control"
                                name='company'
                                type="text"
                                value={formData.company}
                                onChange={handleChange}
                            />
                        </div>

                        <div className='col-md-4 col-12 mt-3'>

                            <label className='labelHeading mb-1'>Specialities<span className='text-danger'>*</span></label>
                            <Multiselect
                                options={specialityOption}
                                selectedValues={speciality}
                                isObject={false}
                                onSelect={onSelect}
                                onRemove={onRemove}
                                displayValue="name"
                                placeholder={placeholder}
                                avoidHighlightFirstOption={true}
                                selectionLimit={4}
                            />
                        </div>
                        <div className='col-md-4 col-12 mt-3'>

                            <label className='labelHeading mb-1'>Profession<span className='text-danger'>*</span></label>
                            <select
                                name="profession"
                                className="form-control pointer"
                                placeholder="Select Profession"
                                value={formData.profession}
                                onChange={handleChange}
                            >
                                <option value="" disabled selected>
                                    Select Profession
                                </option>
                                <option value="Ophthalmologist" className='pointer'>Ophthalmologist</option>
                                <option value="Optometrist" className='pointer'>Optometrist</option>
                            </select>
                        </div>
                        <div className='col-md-4 col-12 mt-3'>

                            <label className='labelHeading mb-1'>Qualification<span className='text-danger'>*</span></label>
                            <select
                                name="qualification"
                                className="form-control pointer"
                                placeholder="Select Qualification"
                                value={formData.qualification}
                                onChange={handleChange}
                            >
                                <option value="" disabled selected>
                                    Select Profession
                                </option>
                                <option value="Doctorate" className='pointer'>Doctorate</option>
                                <option value="Masters" className='pointer'>Masters</option>
                                <option value="Graduate" className='pointer'>Graduate</option>
                                <option value="Diploma" className='pointer'>Diploma</option>
                            </select>
                        </div>

                        <div className='col-md-4 col-12 mt-3'>

                            <label className='labelHeading mb-1'>Years of Experience<span className='text-danger'>*</span></label>
                            <input
                                placeholder='Enter Experience'
                                required
                                className="form-control"
                                name='yearsOfExperience'
                                type="number"
                                value={formData.yearsOfExperience}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='col-md-4 col-12 mt-3'>

                            <label className='labelHeading mb-1'>Per Day Eye Test in Current Practice<span className='text-danger'>*</span></label>
                            <input
                                placeholder='Enter Count'
                                required
                                className="form-control"
                                name='perDayEyeTest'
                                type="number"
                                value={formData.perDayEyeTest}
                                onChange={handleChange}
                            />

                        </div>
                        <div className='col-md-4 col-12 mt-3'>

                            <label className='labelHeading mb-1'>Slit Lamp Available<span className='text-danger'>*</span></label>
                            <select
                                name="slitLampAvailable"
                                className="form-control pointer"
                                placeholder="Select the lamp availability"
                                value={formData.slitLampAvailable}
                                onChange={handleChange}
                            >
                                <option value="" disabled selected>
                                    Select the Lamp availability
                                </option>
                                <option value="true" className='pointer'>Yes</option>
                                <option value="false" className='pointer'>No</option>
                            </select>

                        </div>
                        <div className=' col-md-6 col-12 mt-3'>
                            <label className='labelHeading mb-1'>Edit Address<span className='text-danger'>*</span></label>
                            <SearchBox
                                selectedPosition={selectedPosition}
                                setSelectedPosition={setSelectedPosition}
                                setinnerData={setinnerData}
                            />
                        </div>
                        <div className='col-md-6 col-12 mt-3'>

                            <label className='labelHeading mb-1'>Pin Code</label>
                            <input className='form-control' placeholder='Enter pin code' name='pinCode' value={formData?.pinCode} onChange={handleChange} />

                        </div>
                        <div className='col-md-12 mt-3'>
                            <MapComponent
                                selectedPosition={selectedPosition}
                                setSelectedPosition={setSelectedPosition}
                            />
                        </div>

                    </div>
                    <div className='d-flex justify-content-end mt-3 mb-5'>
                        <button
                            className='btn btn-primary'
                            type='submit'
                            disabled={loading}
                        >
                            {loading ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                                "Submit"
                            )}

                        </button>
                    </div>
                </form>
            </div>
        </Content>
    );
};

export default ProfessionalEdit;
