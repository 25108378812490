import React, { useEffect, useState } from "react";
// import { Table } from "antd";
import Content from "../components/Content";
import { getAppointments } from "../features/appointments/AppointmentService";
import moment from "moment";
import Loader from "../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import AppointmentModal from "./AppointmentModal";
import { Dropdown, Menu } from 'antd';
import { PiDotsThreeVertical } from "react-icons/pi";
import CustomModal from "../components/CustomModal";
import { toast } from "react-toastify";
import TableContainer from "../components/TableContainer/TableContainer";
import { MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import DropDownMenu from "../components/DropDownMenu/DropDownMenu";
import Pagination from "../components/Pagination/Pagination";
import { paginateData } from "../utils/utils";
import { BsThreeDotsVertical } from "react-icons/bs";





const Appointment = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [appointmentData, setAppoinmentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [info, setInfo] = useState("")

  const [page, setPage] = useState(0);
  const rowsPerPage = 5
  const [data, setData] = useState([])
  const [allData, setAllData] = useState([])
  const [allData2, setAllData2] = useState([])
  const [noData, setNoData] = useState(false)





  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };






  useEffect(() => {
    let slicedData = paginateData(page, rowsPerPage, allData)
    setData(slicedData)
  }, [page, rowsPerPage])

 

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getAppointments();
      console.log(response, "get all the appontes---")
     if (response?.length == 0) {
        setNoData(true)
      } else {
        setNoData(false)
      }
      setAllData(response);
      setAllData2(response);
      let slicedData = paginateData(page, rowsPerPage, response)
      setData(slicedData)
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function statusUI(key){
    switch (key) {
      case 'confirmed':
        return <div className="text-info">{key}</div>
        break;
      case 'completed':
        return <div className="text-success">{key}</div>
        break;
      case 'started':
        return <div className="text-warning">{key}</div>
        break;
      case 'professional cancel':
        return <div className="text-danger">Canceled by professional</div>
        break;
      case 'user cancel':
        return <div className="text-danger">Canceled by customer</div>
        break;
      case 're schedule':
        return <div className='text-capitalize' style={{color:'blue'}}>{key}</div>
        break;
      case 'pending':
        return <div className='text-capitalize' style={{color:'blue'}}>{key}</div>
        break;
    
      default:
        return key
        break;
    }
  }

  // const data = appointmentData?.map((item, index) => {
  //   const date = moment(item?.appointmentDateTime).utc().format("DD/MM/YYYY");
  //   const time = moment(item?.appointmentDateTime).utc().format("hh:mm A");

  //   let value = "";

  //   if (item?.status === "pending" || item?.status === "re schedule") {
  //     value = "Requested"
  //   }
  //   else if (item?.status === "confirmed") {
  //     value = "Upcoming"
  //   }
  //   else if (item?.status === "completed") {
  //     value = "Completed"
  //   }
  //   else if (item?.status === "professional cancel" || item.status === "user cancel") {
  //     value = "Cancelled"
  //   }
  //   else if (item.status === "started") {
  //     value = "Ongoing"
  //   }

  //   return {
  //     key: index + 1,
  //     docname: item?.Professional?.firstname,
  //     custname: item?.user?.firstname,
  //     time: time,
  //     date: date,
  //     location: item?.location,
  //     status: value,
  //     action: item,
  //     appointment: item,
  //   };
  // });

  return (
    <Content>
      {isLoading ? <Loader open={isLoading} /> : ""}
      <AppointmentModal
            open={openModal}
            onClose={() => setOpenModal(false)}
            info={info}
            fetchData={fetchData}
          />
          <CustomModal
            hideModal={hideModal}
            open={open}
            // performAction={() => handleManufacturer(cID)}
            title="Are you sure you want to delete this Appointment?"
          />
       <div>
        <h2 className="mb-4">Appointment</h2>
        <div className='row justify-content-md-between justify-content-sm-between justify-content-md-start mt-3'>
          <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12'>
            {/* <input type='search' className='form-control w-100' placeholder='Search professional by name' value={search} onChange={(e) => {
              if (e.target.value == ' ') {
                e.target.value = ''
              } else {
                searchData(e.target.value)
              }
            }} /> */}
          </div>
          <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 d-flex justify-content-end mt-md-0 mt-sm-0 mt-2'>

          </div>
        </div>
        <div className="mt-4">
          <TableContainer>
            <Table>
              {noData ? <caption className='text-center'>No Data Found</caption> : ''}
              <TableHead>
                <TableRow>
                  <TableCell className="fw-bold">Doctor Name</TableCell>
                  <TableCell className="fw-bold">Customer Name</TableCell>
                  <TableCell className="fw-bold">Time</TableCell>
                  <TableCell className="fw-bold">Date</TableCell>
                  <TableCell className="fw-bold">Location</TableCell>
                  <TableCell className="fw-bold">Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((res, index) => {

                  return (
                    <TableRow hover key={index}>
                      <TableCell className="text-capitalize">
                        {res?.Professional?.firstname}
                      </TableCell>
                      <TableCell className="text-capitalize">
                        {res?.user?.firstname}
                      </TableCell>
                      <TableCell className="text-capitalize">
                        {moment(res?.appointmentDateTime).format("hh:mm a")}
                      </TableCell>
                      <TableCell className="text-capitalize">
                        {moment(res?.appointmentDateTime).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell className="">{res?.location}</TableCell>
                      <TableCell className="">
                        {statusUI(res?.status)}
                      </TableCell>
                      {/* <TableCell className='text-capitalize'>{changeStatusUi(res?.profile_status)}</TableCell>
                      <TableCell className='text-capitalize'><button className="btn p-0" onClick={() => {
                        setLocation({ lat: res?.lat_long?.lat || null, lng: res?.lat_long?.long || null })
                        setLocationModal(true)
                      }}><IoEyeOutline className="fs-5" /></button></TableCell> */}
                      <TableCell className="text-capitalize">
                        <DropDownMenu
                          button={
                            <button className="btn btn-sm">
                              <BsThreeDotsVertical className="fs-5" />
                            </button>
                          }
                        >
                          <MenuItem
                            onClick={() => {
                              setOpenModal(true);
                              setInfo(res);
                            }}
                          >
                            Edit
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              // setOpen(true)
                              // setDeleteId(res?._id)
                            }}
                          >
                            Delete
                          </MenuItem>
                        </DropDownMenu>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>

            </Table>
            <Pagination

              allData={allData}
              currentPage={page}
              paginate={(d) => {
                setPage(d);
              }}
              itemPerPage={rowsPerPage}
            />
          </TableContainer>
        </div>
      </div>
    </Content>
  );
};

export default Appointment;
