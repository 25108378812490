import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import Content from "../components/Content";

import CustomModal from "../components/CustomModal";
import { toast } from "react-toastify";
import { deleteCategory, getCategories } from "../features/category/category";
import Loader from "../components/Loader/Loader";

const Categorylist = () => {
  const [cData, setCData] = useState([]);
  const [open, setOpen] = useState(false);
  const [cID, setCID] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const showModal = (id) => {
    setOpen(true);
    setCID(id);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const columns = [
    {
      title: "SNo",
      dataIndex: "key",
    },
    {
      title: "Category ",
      dataIndex: "title",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Image",
      dataIndex: "fileUrl",
      render: (fileUrl) => (
        <img
          src={fileUrl}
          alt="Category"
          style={{ width: "50px", height: "50px", objectFit: "contain" }}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getCategories();
      setCData(response);
      console.log(response)
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const data = cData.map((item, index) => ({
    key: index + 1,
    title: item?.title,
    fileUrl: item?.images[0]?.fileUrl,
    action: (
      <>
        <Link to={`/admin/category/${item?._id}`} className="fs-3 text-success">
          <BiEdit />
        </Link>
        <button
          className="ms-3 fs-3 text-danger bg-transparent border-0"
          onClick={() => showModal(item?._id)}
        >
          <AiFillDelete />
        </button>
      </>
    ),
  }));

  const handleManufacturer = async (cID) => {
    setIsLoading(true);
    try {
      const response = await deleteCategory(cID);
      if (response) {
        toast.success("Category Deleted Successfully!");
        await fetchData();
        setOpen(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Content>
      {isLoading ? <Loader open={isLoading} /> : ""}
      <h4 className="mb-4 title">Category List</h4>
      <div>
        <Table columns={columns} dataSource={data} />
      </div>
      <CustomModal
        hideModal={hideModal}
        open={open}
        performAction={() => handleManufacturer(cID)}
        title="Are you sure you want to delete this Product Category?"
      />
    </Content>
  );
};

export default Categorylist;
